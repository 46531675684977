import React, {PropsWithChildren, useMemo} from 'react';
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import ErrorBoundary from "../ErrorBoundary";
import logo from "../../../assets/images/logo.svg";
import logoIcon from "../../../assets/images/logo_icon.svg";
import {Link, useHistory} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../app/store/hooks";
import {currentCompanyIdSelector, needToUpdateSelector, userDataSelector} from "../../../features/config/slice";
import classNames from "classnames";
import styles from "./Layout.module.scss";
import {changeCurrentCompany} from "../../../features/config/slice";
import {ratesSelector} from "../../../features/rates/redux/slice";
import {getNounCase} from "../../utils/functions";
import WarningIcon from "../Icon/WarningIcon";
import Icon from "../Icon";
import LayoutTopAlert from "./LayoutTopAlert";

/**
 * Каркас приложения
 */
const Layout: React.FC<PropsWithChildren> = ({
  children,
}) => {
  
  const dispatch = useAppDispatch();
  
  const needToUpdate = useAppSelector(needToUpdateSelector);
  
  const {
    companies,
    fio,
  } = useAppSelector(userDataSelector) ?? {};
  const companyId = useAppSelector(currentCompanyIdSelector);
  
  const currentCompany = companies?.find(({id}) => id === companyId);
  
  const rates = useAppSelector(ratesSelector);
  
  /**
   * id компаний, для которых показывать предупреждения
   */
  const companyWarningStatus = useMemo(() => {
    const result: Record<number, boolean> = {};
    
    rates
      .filter(({isActual}) => !isActual)
      .forEach(({companyId}) => {
        if (!result[companyId]) {
          result[companyId] = true;
        }
      })
    
    return result;
  }, [rates])
  
  const companyWarningsNumber = Object.keys(companyWarningStatus).length;
  
  const {location: {pathname}} = useHistory();
  
  const navItems = useMemo(() => ([
    {
      link: '/rates',
      label: 'Мои курсы валют',
      active: pathname.includes('/rates'),
    },
  ]), [pathname]);
  
  return <>
    {needToUpdate && <LayoutTopAlert/>}
    <Navbar className={styles['navbar']}>
      <Container fluid={"xl"}>
        <Navbar.Brand>
          <img
            className="d-none d-md-block mt-2 mb-2"
            src={logo}
            height="48"
            alt="ifin.kz"
          />
          <img
            className="d-block d-md-none mt-2 mb-2"
            src={logoIcon}
            height="48"
            alt="ifin.kz"
          />
        </Navbar.Brand>
        {navItems.map(({link, label}) =>
          <Link className={styles['nav-link']} key={link} to={link}>
            {label}
          </Link>)}
        <div className="flex-grow-1"/>
        <NavDropdown
          id="nav-dropdown"
          align="end"
          title={<div className="d-flex align-items-center">
            <img
              className={classNames(styles['mini-logo'], "me-1 me-lg-2 d-inline-block")}
              src={currentCompany?.iconUrl || ''}
              alt={currentCompany?.fullName}
              width="40"
              height="40"
            />
            <div className="d-none d-lg-block">
              {fio && <div>{fio}</div>}
              <div className={styles['small']}>{currentCompany?.fullName}</div>
              {!!companyWarningsNumber && <div className={styles['warning']}>{companyWarningsNumber} {getNounCase(companyWarningsNumber, ['предупреждение', 'предупреждения', 'предупреждений'])}</div>}
            </div>
            <Icon icon={"expand_more"}/>
          </div>}>
          {companies?.length && companies?.length > 1 && <>
            {companies?.map(({id, fullName, iconUrl}) =>
              <NavDropdown.Item
                disabled={id === companyId}
                onClick={() => dispatch(changeCurrentCompany(id))}
                key={id}>
                <div className="d-flex align-items-center">
                  <img
                    className={classNames(styles['mini-logo'], "me-2")}
                    src={iconUrl || ''}
                    alt={fullName}
                    width="40"
                    height="40"
                  />
                  <div>{fullName}</div>
                  {companyWarningStatus[id] && <WarningIcon className="ms-2"/>}
                </div>
              </NavDropdown.Item>)}
              <NavDropdown.Divider />
          </>}
          <NavDropdown.Item as={"a"} href={'https://ifin.kz/cabinet'}>
            Профиль
          </NavDropdown.Item>
          <NavDropdown.Item as={"a"} href={'https://ifin.kz/logout'}>
            Выход
          </NavDropdown.Item>
        </NavDropdown>
      </Container>
    </Navbar>
    <ErrorBoundary>
      <Container className="px-0 px-lg-2" fluid={"xl"}>
        {children}
      </Container>
    </ErrorBoundary>
  </>;
};

export default Layout;
