import React, {PropsWithChildren} from 'react';
import styles from "./Box.module.scss";
import classNames from "classnames";
import {BoxProps} from "./types";

const Box: React.FC<PropsWithChildren<BoxProps>> = ({
  children,
  className,
  state,
}) =>
  <div
    className={classNames(
      styles.box,
      state?.map(cl => styles[cl]),
      className,
    )}>
    {children}
  </div>;

export default Box;
