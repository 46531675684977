import {addParametersToEndpoint, APIRequestParameters, request} from "../../common/utils/apiHelpers";
import {AxiosResponse} from "axios";
import {CurrencyResponse, ExchangeRateForm, ExchangeRateResponse} from "./models";

/**
 * Получение курсов валют
 */
export const requestExchangeRates = (
  params: APIRequestParameters = {},
): Promise<AxiosResponse<ExchangeRateResponse[]>> =>
  request(
    addParametersToEndpoint(`/exchange/search`, params),
    'POST',
  )

type ExchangeRateEditQueryParams = {
  companyId: number;
  cityId?: number;
  branchId?: number;
}

/**
 * Сохранение курса валют
 */
export const requestExchangeRateEdit = (
  form: ExchangeRateForm[],
  params: APIRequestParameters & ExchangeRateEditQueryParams,
): Promise<AxiosResponse<ExchangeRateResponse[]>> =>
  request(
    addParametersToEndpoint(`/exchange/save`, params),
    'POST',
    form,
  )

/**
 * Получение информации о активных валютах
 */
export const requestCurrencies = (): Promise<AxiosResponse<CurrencyResponse[]>> =>
  request(
    `/currency`,
    'GET',
  )

/**
 * Удаление курса валют
 */
export const requestExchangeRateDelete = (
  params: ExchangeRateEditQueryParams,
): Promise<AxiosResponse> =>
  request(
    addParametersToEndpoint(`/exchange`, params),
    'DELETE'
  )
