import {Action} from "redux";
import reducer from "./rootReducer";
import {ThunkAction} from "redux-thunk";
import {configureStore} from "@reduxjs/toolkit";

export const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== 'production',
})

/**
 * Тип thunk-действия
 * (возвращает void, использует RootState, доп. параметр unknown, redux-действия используют T)
 */
export type AppThunkAction<T extends Action> = ThunkAction<void, RootState, unknown, T>;

/**
 * Тип всего состояния
 */
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch

/**
 * Базовое состояние для формы просмотра/изменения сущности
 */
export interface EntityFormState<Entity> {
  readonly data: Entity | null;
  readonly loading: boolean;
  readonly error: string | null;
}

/**
 * Базовое состояние для хранения списка сущностей
 */
export interface EntityListState<Entity> {
  readonly data: Entity[];
  readonly loading: boolean;
  readonly error: string | null;
}

export type DataRequestActions<Entity, RequestType, SuccessType, ErrorType> = {
  /**
   * Запрос на получение сущности
   */
  type: RequestType;
} | {
  /**
   * Успех получения сущности
   */
  type: SuccessType;
  payload: Entity;
} | {
  /**
   * Ошибка получения сущности
   */
  type: ErrorType;
  payload: string;
}
