import React from 'react';
import Spinner from "react-bootstrap/Spinner";
import {LoaderProps} from "./types";
import classNames from "classnames";

const Loader: React.FC<LoaderProps> = ({
  className,
  ...spinnerProps
}) =>
  <div className={classNames("d-flex justify-content-center", className)}>
    <Spinner animation="border" variant="primary" {...spinnerProps}/>
  </div>;

export default Loader;
