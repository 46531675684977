import {AxiosError} from "axios";
import {parse, format} from "date-fns";
import ru from "date-fns/locale/ru";

/**
 * Замена объекта в массиве по id
 * @returns новый массив (исходный, если не нашел id или некорректные типы аргументов)
 */
export function replaceInData<T extends {id?: number} = Record<string, unknown>>(
  items: T[],
  item: T,
): T[] {
  /**
   * Ранний выход, если некорректные типы аргументов
   */
  if (!Array.isArray(items) || item.constructor !== Object) {
    return items;
  }
  
  const index = item.id
    ? items.findIndex(({id}) => id === item.id)
    : -1;
  
  return index === -1
    ? items
    : [
      ...items.slice(0, index),
      item,
      ...items.slice(index + 1)
    ];
}

/**
 * Создать уникальный идентификатор
 * @link https://gist.github.com/gordonbrander/2230317?permalink_comment_id=3752738#gistcomment-3752738
 */
export const generateUUID = (): string => {
  const array = new Uint32Array(8);
  window.crypto.getRandomValues(array);
  let str = '';
  
  for (let i = 0; i < array.length; i++) {
    str += (i < 2 || i > 5 ? '' : '-') + array[i].toString(16).slice(-4)
  }
  
  return str;
}

/**
 * Получение сообщения об ошибке из объекта ошибки запроса axios
 */
export const getAxiosErrorMessage = (
  {response, request}: AxiosError<{message?: string;}>,
  fallbackMessage = "Ошибка",
): string => {
  if (response?.data?.message) {
    return response.data.message;
  }
  
  if (request) {
    return "Ошибка соединения с сервером"
  }
  
  return fallbackMessage;
}

/**
 * Получить объект даты из строки
 * @param input - вход
 * @param format - формат, по умолчанию серверный
 */
export const getDateFromString = (
  input: string,
  format = 'yyyy-MM-dd HH:mm:ssx',
): Date =>
  parse(input, format, new Date(), {locale: ru})

/**
 * Получить выводимую строку из объекта даты
 */
export const formatDateAsString = (
  date: Date,
  outputFormat = 'dd.MM.yyyy HH:mm',
): string => format(date, outputFormat);

/**
 * Получение существительного в правильном падеже в зависимости от числительного
 * @param number - числительное
 * @param titles - формы существительного (1, 2-5, 6-0)
 */
export const getNounCase = (number: number, titles: [string, string, string]): string => {
  number = Math.abs(number);
  if (Number.isInteger(number)) {
    const cases = [2, 0, 1, 1, 1, 2];
    
    return titles[(number % 100 > 4 && number % 100 < 20)
      // x05-x19
      ? 2
      : cases[(number % 10 < 5)
        // xx0 - xx4
        ? number % 10
        // xx5 - xx9
        : 5
        ]
      ];
  }
  // для дробных чисел
  return titles[1];
}
