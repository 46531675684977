import React from 'react';
import {ConfirmDialogProps} from "./types";
import Dialog from "./index";
import Button from "react-bootstrap/Button";

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  show,
  onHide,
  title,
  actionHandler,
  actionButtonLabel,
  description,
}) =>
  <Dialog
    show={show}
    onHide={onHide}
    title={title}
    body={description
      ? <div className="px-3 py-4">{description}</div>
      : null}
    footer={<>
      <Button className="me-3" onClick={onHide} variant={"primary"}>
        Отмена
      </Button>
      <Button onClick={actionHandler} variant={"primary"}>
        {actionButtonLabel}
      </Button>
    </>}
  />;

export default ConfirmDialog;
