import {AppThunkAction} from "../../app/store/store";
import {getAxiosErrorMessage} from "../utils/functions";
import {requestBranches} from "../../features/config/api";
import {getBranchesFailure, getBranchesRequest, getBranchesSuccess} from "../slices/branches";
import {Action} from "redux";

export const getBranches = (
  companyId?: number,
  cityId?: number,
): AppThunkAction<Action> =>
  dispatch => {
    
    dispatch(getBranchesRequest());
    
    requestBranches({companyId, cityId})
      .then(({data}) => {
        dispatch(getBranchesSuccess(data || []));
      })
      .catch((err) => {
        dispatch(getBranchesFailure(getAxiosErrorMessage(err)));
      });
  };
