import {addParametersToEndpoint, APIRequestParameters, request} from "../../common/utils/apiHelpers";
import {AxiosResponse} from "axios";
import {BranchResponse, CityResponse, UserResponse} from "./models";

/**
 * Запросить данные о текущем пользователе
 */
export const requestUserData = (
  params: APIRequestParameters = {},
): Promise<AxiosResponse<UserResponse>> =>
  request(
    addParametersToEndpoint(`/user`, params),
    'GET'
  )

/**
 * Получение списка доступных городов для компании
 */
export const requestCities = (
  params: APIRequestParameters & {companyId?: number;} = {},
): Promise<AxiosResponse<CityResponse[] | ''>> =>
  request(
    addParametersToEndpoint(`/city`, params),
    'GET',
  )

/**
 * Получение списка доступных отделений для компании
 */
export const requestBranches = (
  params: APIRequestParameters & {companyId?: number;} = {},
): Promise<AxiosResponse<BranchResponse[] | ''>> =>
  request(
    addParametersToEndpoint(`/branch`, params),
    'GET',
  )
