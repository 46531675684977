import {UserResponse} from "./models";
import {RootState} from "../../app/store/store";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getDefaultCompanyId} from "./helpers";

type ConfigState = {
  user: UserResponse | null;
  loading: boolean;
  error: string | null;
  currentCompanyId: number | null;
  needToUpdate: boolean;
}

const initialState: ConfigState = {
  user: null,
  loading: false,
  error: null,
  currentCompanyId: null,
  needToUpdate: false,
}

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    getUserRequest: (state) => ({
      ...state,
      user: null,
      loading: true,
      error: null,
      currentCompanyId: null,
    }),
    getUserSuccess: (state, action: PayloadAction<UserResponse>) => ({
      ...state,
      loading: false,
      user: action.payload,
      error: null,
      currentCompanyId: getDefaultCompanyId(action.payload),
    }),
    getUserFailure: (state, action: PayloadAction<string>) => ({
      ...state,
      loading: false,
      user: null,
      error: action.payload,
      currentCompanyId: null,
    }),
    /**
     * Изменить выбранную компанию на другую
     */
    changeCurrentCompany: (state, action: PayloadAction<number>) => ({
      ...state,
      currentCompanyId: action.payload,
    }),
    /**
     * Изменить статус необходимости обновления
     */
    setNeedToUpdate: (state, action: PayloadAction<boolean>) => ({
      ...state,
      needToUpdate: action.payload,
    }),
  }
})

export const userDataSelector = ({config: {user}}: RootState): ConfigState['user'] => user;
export const currentCompanyIdSelector = ({config: {currentCompanyId}}: RootState): ConfigState['currentCompanyId'] => currentCompanyId;
// export const userErrorSelector = ({config: {error}}: RootState): ConfigState['error'] => error;
export const needToUpdateSelector = ({config: {needToUpdate}}: RootState): ConfigState['needToUpdate'] => needToUpdate;

export const {
  getUserRequest,
  getUserSuccess,
  getUserFailure,
  changeCurrentCompany,
  setNeedToUpdate,
} = configSlice.actions;
export default configSlice.reducer;
