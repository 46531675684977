import {AppThunkAction} from "../../app/store/store";
import {requestCurrencies} from "../../features/rates/api";
import {getAxiosErrorMessage} from "../utils/functions";
import {getCurrenciesFailure, getCurrenciesRequest, getCurrenciesSuccess} from "../slices/dictionaryCurrencies";
import {Action} from "redux";
import {checkIfNeedToUpdate} from "../../features/config/actions";

export const getDictionaryCurrencies = (): AppThunkAction<Action> =>
  dispatch => {

    dispatch(getCurrenciesRequest());

    requestCurrencies()
      .then(({data, headers}) => {
        dispatch(checkIfNeedToUpdate(headers));
        dispatch(getCurrenciesSuccess(data || []));
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          window.location.replace('https://ifin.kz/login');
          return;
        }

        dispatch(getCurrenciesFailure(getAxiosErrorMessage(err)))
      });
  };
