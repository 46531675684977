import React, { Component, ErrorInfo } from "react";
import Alert from "react-bootstrap/Alert";
import {ErrorBoundaryProps, ErrorBoundaryState} from "./types";

/**
 * Компонент - предохранитель
 */
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public state: ErrorBoundaryState = {
    hasError: false
  };
  
  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error("Uncaught error:", error, errorInfo);
  }
  
  public render(): React.ReactNode {
    if (this.state.hasError) {
      return <ErrorBoundaryAlert/>
    }
    
    return this.props.children;
  }
}

const ErrorBoundaryAlert = () =>
  <Alert className={'container'} variant="danger">
    Что-то пошло не так, обновите страницу
  </Alert>

export default ErrorBoundary;
