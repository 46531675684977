import React, {useState} from 'react';
import {RatesListCurrenciesDialogProps} from "./types";
import {useAppSelector} from "../../../../../../app/store/hooks";
import {dictionaryCurrenciesListSelector} from "../../../../../../common/slices/dictionaryCurrencies";
import Dialog from "../../../../../../common/components/Dialog";
import Checkbox from "../../../../../../common/components/Checkbox";
import Button from "react-bootstrap/Button";
import classNames from "classnames";
import styles from "./RatesListCurrenciesDialog.module.scss";

const RatesListCurrenciesDialog: React.FC<RatesListCurrenciesDialogProps> = ({
  show,
  onHide,
  usedCurrencyIds,
  applyCurrencyChanges,
}) => {
  
  const dictionaryCurrencies = useAppSelector(dictionaryCurrenciesListSelector);
  
  const [chosenCurrencyIds, setChosenCurrencyIds] = useState(usedCurrencyIds);
  
  return <Dialog
    show={show}
    onHide={onHide}
    title={"Настройка списка валют"}
    body={<div className={classNames(styles.container)}>
      {dictionaryCurrencies.map(({id, iconUrl, name}) =>
        <div className="d-flex align-items-center mb-1" key={id}>
          <img alt={name} src={iconUrl || ''} width={24}/>
          <div className="ms-2 flex-grow-1">{name}</div>
          <div className={styles['checkbox-wrapper']}>
            <Checkbox
              checked={chosenCurrencyIds.includes(id)}
              id={`currency-add-dialog-${id}`}
              onChange={(e) => {
                if (e.target.checked) {
                  if (!chosenCurrencyIds.includes(id)) {
                    setChosenCurrencyIds(prevState => [...prevState, id]);
                  }
                  
                  return;
                }
  
                if (chosenCurrencyIds.includes(id)) {
                  setChosenCurrencyIds(prevState => prevState.filter(idInState => idInState !== id))
                }
              }}
            />
          </div>
        </div>)}
    </div>}
    footer={<>
      <Button className="me-3" onClick={onHide} variant={"primary"}>
        Отмена
      </Button>
      <Button onClick={() => applyCurrencyChanges(chosenCurrencyIds)} variant={"primary"}>
        Сохранить
      </Button>
    </>}
  />
}

export default RatesListCurrenciesDialog;
