import React from 'react';
import icon from '../../../assets/images/warning_red.svg';
import styles from "./Icon.module.scss";
import {WarningIconProps} from "./types";
import classNames from "classnames";

/**
 * Красная svg-иконка предупреждения, обернута в класс размеров иконки, чтобы иметь размер 24х24
 */
const WarningIcon: React.FC<WarningIconProps> = ({className}) =>
  <div className={classNames("d-flex justify-content-center align-items-center", styles['icon-size'], className)}>
    <img
      alt="!"
      height={19}
      src={icon}
      width={22}
    />
  </div>;

export default WarningIcon;
