import {AppThunkAction} from "../../app/store/store";
import {getAxiosErrorMessage} from "../utils/functions";
import {requestCities} from "../../features/config/api";
import {getCitiesFailure, getCitiesRequest, getCitiesSuccess} from "../slices/cities";
import {Action} from "redux";

export const getCities = (
  companyId?: number,
): AppThunkAction<Action> =>
  dispatch => {
    
    dispatch(getCitiesRequest());
    
    requestCities({companyId})
      .then(({data}) => {
        dispatch(getCitiesSuccess(data || []));
      })
      .catch((err) => {
        dispatch(getCitiesFailure(getAxiosErrorMessage(err)))
      });
  };
