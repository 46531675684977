import React from 'react';
import {IconButtonProps} from "./types";
import Icon from "./index";
import classNames from "classnames";
import styles from "./Icon.module.scss";

/**
 * Кнопка-иконка
 */
const IconButton: React.FC<IconButtonProps> = ({
  onClick,
  wrapperClassName,
  disabled,
  ...iconProps
}) =>
  <div
    className={classNames(
      styles['icon-size'],
      disabled && styles['disabled'],
      wrapperClassName,
    )}
    onClick={onClick}
    role='button'>
    <Icon {...iconProps}/>
  </div>;

export default IconButton;
