import {EntityListState, RootState} from "../../../app/store/store";
import {ExchangeRateResponse} from "../models";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

type RatesState = EntityListState<ExchangeRateResponse> & {
  updateLoading: boolean;
  updateError: string | null;
  actionLoading: boolean;
  actionError: string | null;
}

const initialState: RatesState = {
  data: [],
  loading: false,
  error: null,
  updateLoading: false,
  updateError: null,
  actionLoading: false,
  actionError: null,
}

const ratesSlice = createSlice({
  name: 'rates',
  initialState,
  reducers: {
    getRatesRequest: () => ({
      ...initialState,
      loading: true,
    }),
    getRatesSuccess: (state, action: PayloadAction<ExchangeRateResponse[]>) => ({
      ...initialState,
      data: action.payload,
    }),
    getRatesFailure: (state, action: PayloadAction<string>) => ({
      ...initialState,
      error: action.payload,
    }),
    updateRatesRequest: (state) => ({
      ...state,
      updateLoading: true,
      updateError: null,
    }),
    updateRatesFailure: (state, action: PayloadAction<string>) => ({
      ...state,
      updateLoading: false,
      updateError: action.payload,
    }),
    processRatesRequest: state => ({
      ...state,
      actionLoading: true,
      actionError: null,
    }),
    processRatesSuccess: state => ({
      ...state,
      actionLoading: false,
      actionError: null,
    }),
    processRatesFailure: (state, action: PayloadAction<string>) => ({
      ...state,
      actionLoading: false,
      actionError: action.payload,
    }),
    clearRatesEditError: state => ({
      ...state,
      actionError: null,
    }),
    clearRatesUpdateError: state => ({
      ...state,
      updateError: null,
    })
  }
})

export const ratesSelector = ({rates: {data}}: RootState): RatesState['data'] => data;
export const ratesLoadingSelector = ({rates: {loading}}: RootState): RatesState['loading'] => loading;
export const ratesErrorSelector = ({rates: {error}}: RootState): RatesState['error'] => error;
export const ratesActionLoadingSelector = ({rates: {actionLoading}}: RootState): RatesState['actionLoading'] => actionLoading;
export const ratesActionErrorSelector = ({rates: {actionError}}: RootState): RatesState['actionError'] => actionError;
export const ratesUpdateLoadingSelector = ({rates: {updateLoading}}: RootState): RatesState['updateLoading'] => updateLoading;
export const ratesUpdateErrorSelector = ({rates: {updateError}}: RootState): RatesState['updateError'] => updateError;

export const {
  getRatesRequest,
  getRatesSuccess,
  getRatesFailure,
  processRatesRequest,
  processRatesSuccess,
  processRatesFailure,
  clearRatesEditError,
  updateRatesRequest,
  updateRatesFailure,
  clearRatesUpdateError,
} = ratesSlice.actions;
export default ratesSlice.reducer;
