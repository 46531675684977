import {AppThunkAction} from "../../app/store/store";
import {requestUserData} from "./api";
import {Action} from "redux";
import {getUserFailure, getUserRequest, getUserSuccess, setNeedToUpdate} from "./slice";
import {getAxiosErrorMessage} from "../../common/utils/functions";
import {AxiosError, AxiosResponseHeaders, RawAxiosResponseHeaders} from "axios";

/**
 * Получение пользователя
 */
export const getUser = (
  expand = '',
): AppThunkAction<Action> => dispatch => {
  dispatch(getUserRequest());

  requestUserData({expand})
    .then(({data, headers}) => {
      dispatch(checkIfNeedToUpdate(headers));
      dispatch(getUserSuccess(data));
    })
    .catch((err: AxiosError<{message?: string;}>) => {
      if (err?.response?.status === 403) {
        window.location.replace('https://ifin.kz/login');
        return;
      }

      dispatch(getUserFailure(getAxiosErrorMessage(err)))
    })
}

/**
 * Проверка необходимости обновлений по заголовкам запроса
 */
export const checkIfNeedToUpdate = (
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders
): AppThunkAction<Action> => (dispatch, getState) => {
  if (headers['x-app-need-update'] && !getState().config.needToUpdate) {
    dispatch(setNeedToUpdate(true));
  }
}
