/** Версии приложения, см. ниже */
const majorVersion = 1;
const minorVersion = 1;
const patch = 1;

/**
 * Версия приложения, формат основан на semver.
 * Первая цифра - мажорная, потом две - минорная, потом две - патч.
 * @example 10100 - изначальная версия
 */
export const APP_VERSION = String(majorVersion * 10000 + minorVersion * 100 + patch);
