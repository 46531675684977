import {
  EntityListState,
  RootState
} from "../../app/store/store";
import {CityResponse} from "../../features/config/models";
import {createSlice} from "@reduxjs/toolkit";
import {
  entityListFailureCaseReducer,
  entityListRequestCaseReducer,
  entityListSuccessCaseReducer
} from "../../app/store/helpers";

type CitiesState = EntityListState<CityResponse>

const initialState: CitiesState = {
  data: [],
  loading: false,
  error: null
};

const citiesSlice = createSlice({
  name: 'cities',
  initialState,
  reducers: {
    getCitiesRequest: entityListRequestCaseReducer,
    getCitiesSuccess: entityListSuccessCaseReducer,
    getCitiesFailure: entityListFailureCaseReducer,
  }
})

/**
 * Селектор городов вне компонента, чтобы идентичность функции была стабильна и не вызывала ререндеров
 */
export const citiesListSelector = ({cities: {data}}: RootState): CitiesState["data"] => data;
export const citiesLoadingSelector = ({cities: {loading}}: RootState): CitiesState["loading"] => loading;
export const citiesErrorSelector = ({cities: {error}}: RootState): CitiesState["error"] => error;

export const {
  getCitiesRequest,
  getCitiesSuccess,
  getCitiesFailure,
} = citiesSlice.actions;
export default citiesSlice.reducer;
