import {
  EntityListState,
  RootState,
} from "../../app/store/store";
import {CurrencyResponse} from "../../features/rates/models";
import {createSlice} from "@reduxjs/toolkit";
import {
  entityListFailureCaseReducer,
  entityListRequestCaseReducer,
  entityListSuccessCaseReducer
} from "../../app/store/helpers";

type DictionaryCurrenciesState = EntityListState<CurrencyResponse>

const initialState: DictionaryCurrenciesState = {
  data: [],
  loading: false,
  error: null
};

const sliceName = 'dictionaryCurrencies';

// const getDictionaryCurrencies = createAsyncThunk(
//   `${sliceName}/get`,
//   async () => {
//     const response = await requestCurrencies();
//     return response.data;
//   }
// )

const dictionaryCurrenciesSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    getCurrenciesRequest: entityListRequestCaseReducer,
    getCurrenciesSuccess: entityListSuccessCaseReducer,
    getCurrenciesFailure: entityListFailureCaseReducer,
  },
  // extraReducers: builder => {
  //   builder
  //     .addCase(getDictionaryCurrencies.pending,
  //       () => ({
  //         data: [],
  //         loading: true,
  //         error: null,
  //       }))
  //     .addCase(getDictionaryCurrencies.fulfilled,
  //       (state, action) => ({
  //         data: action.payload,
  //         loading: false,
  //         error: null,
  //       }))
  //     .addCase(getDictionaryCurrencies.rejected,
  //       (state, action) => ({
  //         data: [],
  //         loading: false,
  //         error: getAxiosErrorMessage(action.payload),
  //       }))
  // }
})

/**
 * Селектор списка валют вне компонента, чтобы идентичность функции была стабильна и не вызывала ререндеров
 */
export const dictionaryCurrenciesListSelector = ({dictionaryCurrencies: {data}}: RootState): DictionaryCurrenciesState["data"] => data;
export const dictionaryCurrenciesLoadingSelector = ({dictionaryCurrencies: {loading}}: RootState): DictionaryCurrenciesState["loading"] => loading;
export const dictionaryCurrenciesErrorSelector = ({dictionaryCurrencies: {error}}: RootState): DictionaryCurrenciesState["error"] => error;

export const {
  getCurrenciesRequest,
  getCurrenciesSuccess,
  getCurrenciesFailure
} = dictionaryCurrenciesSlice.actions;
export default dictionaryCurrenciesSlice.reducer;
