import React, {useEffect} from 'react';
import {Redirect, Route, Router, Switch} from 'react-router-dom';
import {customHistory} from "../common/utils/history";
import Layout from "../common/components/Layout";
import RatesOutput from "../features/rates/components/RatesOutput";
import {useAppDispatch} from "./store/hooks";
import {getUser} from "../features/config/actions";
import {getDictionaryCurrencies} from "../common/actions/dictionaryCurrencies";
import {getRates} from "../features/rates/redux/actions";

function App(): JSX.Element {
  
  const dispatch = useAppDispatch();
  
  /**
   * В среде разработки этот хук сработает и запросит дважды (особенность React 18 для лучшей разработки),
   * но в продакшн сработает только один раз
   */
  useEffect(() => {
    dispatch(getUser())
    dispatch(getDictionaryCurrencies())
  }, [dispatch]);
  
  /**
   * Обновление курсов каждые 5 минут
   */
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getRates({isSilent: true, isAutoPull: true}));
    }, RATES_UPDATE_INTERVAL)
    
    return () => {
      clearInterval(interval);
    }
  }, [dispatch]);
  
  return <>
    <Router history={customHistory}>
      <Switch>
        <Layout>
          <Route path="/" component={RatesOutput}/>
          <Redirect to="/"/>
        </Layout>
      </Switch>
    </Router>
  </>;
}

/** интервал обновления - 5 минут */
const RATES_UPDATE_INTERVAL = 5 * 60 * 1000;

export const RATES_UPDATE_SUCCESS_EVENT = 'RATES_UPDATE_SUCCESS';
export const RATES_SAVE_SUCCESS_EVENT = 'RATES_SAVE_SUCCESS';

export default App;
