import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../app/store/hooks";
import Box from "../../../../common/components/Box";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import _groupBy from "lodash.groupby";
import {currentCompanyIdSelector, userDataSelector} from "../../../config/slice";
import RatesList from "./RatesList";
import Loader from "../../../../common/components/Loader";
import WarningIcon from "../../../../common/components/Icon/WarningIcon";
import Alert from "react-bootstrap/Alert";
import {
  ratesActionLoadingSelector,
  ratesErrorSelector,
  ratesLoadingSelector,
  ratesSelector,
  clearRatesEditError, clearRatesUpdateError,
} from "../../redux/slice";
import {getRates} from "../../redux/actions";
import RateAddDialog from "../RateAddDialog";
import styles from "./RatesOutput.module.scss";
import classNames from "classnames";
import Dropdown from "react-bootstrap/Dropdown";
import NavLink from "react-bootstrap/NavLink";
import {getNounCase} from "../../../../common/utils/functions";
import Icon from "../../../../common/components/Icon";
import {gridBreakpoints} from "../../../../common/utils/styles";
import ConfirmDialog from "../../../../common/components/Dialog/ConfirmDialog";
import {compareRateTabs, getRateTabKey, RATE_TABS_GENERAL_KEY, RatesOutputTab} from "../../helpers";

/**
 * Вывод списка курсов компании
 */
const RatesOutput: React.FC = () => {
  
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    dispatch(getRates());
  }, [dispatch]);
  
  const companyId = useAppSelector(currentCompanyIdSelector);
  
  const data = useAppSelector(ratesSelector);
  const loading = useAppSelector(ratesLoadingSelector);
  const error = useAppSelector(ratesErrorSelector);
  
  /**
   * Открыт ли диалог добавления курса
   */
  const [isAddRateDialog, setIsAddRateDialog] = useState(false);
  
  const openAddRateDialog = useCallback(() => setIsAddRateDialog(true), []);
  const closeAddRateDialog = useCallback(() => setIsAddRateDialog(false), []);
  
  const {
    restrictions,
  } = useAppSelector(userDataSelector) ?? {};
  
  const currentCompanyRestrictions = companyId && restrictions
    ? restrictions.find(r => r.companyId === companyId) || null
    : null;
  
  /**
   * Скрывать кнопку добавления курсов, если пользователь не имеет прав на добавление
   * (если cityIds или branchIds - пустой массив)
   */
  const cannotAddRates = currentCompanyRestrictions
    && (
      (Array.isArray(currentCompanyRestrictions.cityIds) && !currentCompanyRestrictions.cityIds.length)
      || (Array.isArray(currentCompanyRestrictions.branchIds) && !currentCompanyRestrictions.branchIds.length)
    );
  
  const actionLoading = useAppSelector(ratesActionLoadingSelector);
  
  /**
   * Вкладки
   */
  const tabs = useMemo(() => {
    
    const groupedByCity = _groupBy(
      data.filter(rate => rate.companyId === companyId),
      'cityId'
    );
    
    const result: RatesOutputTab[] = [];
  
    /**
     * Общий курс, если он есть, должен быть в самом начале
     */
    if (groupedByCity.null?.length) {
      const showActualityWarning = !!groupedByCity.null?.find(({isActual}) => !isActual);
      
      result.push({
        key: getRateTabKey(null, null),
        title: <span className={styles.title}>
          {showActualityWarning && <WarningIcon className="me-1"/>}
          Общий курс компании
        </span>,
        innerTitle: 'Общий курс компании',
        cityId: null,
        branchId: null,
        rates: groupedByCity.null || [],
        showActualityWarning,
      })
    }
    
    Object.entries(groupedByCity).forEach(([cityId, ratesWithSameCityId]) => {
  
      /**
       * Общий курс компании уже обработан выше
       */
      if (cityId === 'null') {
        return;
      }
      
      Object.entries(_groupBy(ratesWithSameCityId, 'branchId')).forEach(([branchId, ratesWithSameCityAndBranchId]) => {
  
        /**
         * Если branchId равно null, то курсы действуют в рамках города
         */
        if (branchId === 'null') {
          /**
           * Проверка актуальности курсов среди курсов с таким же cityId и с branchId равным null
           * (branchId проверяется, чтобы неактуальность отделений внутри города не показывала неактуальность города)
           */
          const showActualityWarning = !!ratesWithSameCityAndBranchId.find(({isActual}) => !isActual);
  
          const innerTitle = ratesWithSameCityId[0]?.city?.name
            ? `Курс по г. ${ratesWithSameCityId[0].city.name}`
            : '';
            
          result.push({
            key: getRateTabKey(cityId, null),
            title: ratesWithSameCityId[0]?.city?.name
              ? <span className={styles.title}>
                  {showActualityWarning && <WarningIcon className="me-1"/>}
                  {innerTitle}
                </span>
              : "Курс",
            innerTitle: innerTitle,
            cityId: cityId === 'null' ? null : Number(cityId),
            branchId: branchId === 'null' ? null : Number(branchId),
            rates: ratesWithSameCityAndBranchId,
            showActualityWarning,
          })
          
          return;
        }
        
        const showActualityWarning = !!ratesWithSameCityAndBranchId.find(({isActual}) => !isActual);
        const branchName = ratesWithSameCityAndBranchId[0]?.branch?.name;
        const branchAddress = ratesWithSameCityAndBranchId[0]?.branch?.address || '';
        
        result.push({
          key: getRateTabKey(cityId, branchId),
          title: <div className={classNames(styles.title, styles['title--two-lines'])}>
            {showActualityWarning && <WarningIcon className="me-1"/>}
            <div className="text-start">
              <div className={styles['title-main']}>{branchName || 'Отделение'}</div>
              <div className={styles['title-additional']}>{branchAddress}</div>
            </div>
          </div>,
          innerTitle: `Курс валют для отделения${branchName ? ` ${branchName}` : ''}`,
          cityId: cityId === 'null' ? null : Number(cityId),
          branchId: branchId === 'null' ? null : Number(branchId),
          rates: ratesWithSameCityAndBranchId,
          showActualityWarning,
        })
      })
    })
    
    return result.sort(compareRateTabs);
  }, [companyId, data])
  
  /**
   * При переключении вкладок теряются несохраненные изменения.
   * Этот реф показывает наличие таких изменений по аналогии с dirty из Formik-а.
   */
  const currentTabFormDirtyRef = useRef(false);
  
  /**
   * Текущая вкладка. При загрузке приложения это вкладка "Общий курс компании" или, если такой нет, это первая в массиве
   */
  const [tabKey, setTabKey] = useState(tabs.find(({key}) => key === RATE_TABS_GENERAL_KEY)
    ? RATE_TABS_GENERAL_KEY
    : tabs[0]?.key || RATE_TABS_GENERAL_KEY);
  
  /**
   * При переключении компаний компонент не демонтируется и состояние может оставаться прежним.
   * В том числе и ключ вкладки, который может не подходить к вкладкам новой компании
   * (такого города/отделения может не быть у новой компании).
   * Так что при несоответствии ключей автоматически выбирается одна из существующих вкладок.
   */
  useEffect(() => {
    if (tabs.length && !tabs.find(({key}) => key === tabKey)) {
      setTabKey(tabs[0].key);
    }
  }, [tabKey, tabs]);
  
  const changeTab = useCallback((newKey: string) => {
    setTabKey(newKey);
    dispatch(clearRatesEditError());
    dispatch(clearRatesUpdateError());
    currentTabFormDirtyRef.current = false;
  }, [dispatch]);
  
  /**
   * Ключ текущей вкладки, если вкладка переключается и нужно подтвердить переключение
   * (при переключении теряются все изменения)
   */
  const [tabKeyToConfirm, setTabKeyToConfirm] = useState<string | null>(null);
  
  const openTabChangeDialog = useCallback((newKey: string) => setTabKeyToConfirm(newKey), []);
  const closeTabChangeDialog = useCallback(() => setTabKeyToConfirm(null), []);
  
  const confirmTabChange = useCallback(() => {
    if (tabKeyToConfirm) {
      changeTab(tabKeyToConfirm);
      closeTabChangeDialog();
    }
  }, [changeTab, closeTabChangeDialog, tabKeyToConfirm])
  
  const onTabSelect = useCallback((newKey: string | null) => {
    if (!newKey) {
      return;
    }
    
    /**
     * Если текущая вкладка содержит несохраненные изменения, нужно подтвердить переключение
     */
    if (currentTabFormDirtyRef.current) {
      openTabChangeDialog(newKey);
      return;
    }
    
    changeTab(newKey);
  }, [changeTab, openTabChangeDialog]);
  
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  
  useEffect(() => {
    const resizeHandler = () => {
      setScreenWidth(window.innerWidth);
    }
    
    window.addEventListener('resize', resizeHandler);
    
    return () => {
      window.removeEventListener('resize', resizeHandler);
    }
  }, []);
  
  if (loading || (data.length && !tabs.length)) {
    return <Loader className="mt-4"/>
  }
  
  if (error) {
    return <Alert className="mt-4" variant={"danger"}>
      {error}
    </Alert>
  }
  
  if (!data.length) {
    return <div>Курсов нет</div>
  }
  
  const isMobileLayout = screenWidth < gridBreakpoints.lg;
  
  /**
   * Вкладки, которые не поместились в ряд и спрятаны в выпадающем списке
   * (на мобильном нет ряда, поэтому это все вкладки)
   */
  const tabsInDropdown = isMobileLayout
    ? tabs
    : tabs.slice(3);
  
  const tabsInDropdownKeys = tabsInDropdown.map(({key}) => key);
  
  /**
   * true, если у некоторых вкладок в выпадающем списке есть предупреждения
   */
  const someTabsInDropdownHaveWarnings = tabsInDropdown.find(({showActualityWarning}) => showActualityWarning)
  
  /**
   * Показывать заголовок выпадающего списка как активный, если выбрана вкладка из выпадающего списка.
   * В мобильном дизайне все вкладки всегда в выпадающем списке.
   */
  const isDropdownTabActive = isMobileLayout || tabsInDropdownKeys.includes(tabKey);
  
  const dropdownTitle = <div className={classNames("justify-content-between justify-content-lg-start", styles.title)}>
    {isDropdownTabActive
      ? <span className={styles['active-nav-link']}>
          {tabsInDropdown.find(({key}) => key === tabKey)?.title}
        </span>
      : <span className='d-flex align-items-center'>
          {someTabsInDropdownHaveWarnings && <WarningIcon className="me-1"/>}
          Еще {tabsInDropdownKeys.length} {getNounCase(tabsInDropdownKeys.length, ['курс', 'курса', 'курсов'])}
        </span>}
    <Icon
      className={classNames("ms-1", isDropdownTabActive && styles['active-nav-link'])}
      icon="expand_more"
      state={isMobileLayout ? undefined : ['icon--small']}
    />
  </div>
  
  return <>
    <div className={classNames("mt-0 mt-lg-4", styles['anchor'])}>
      {!actionLoading && !cannotAddRates && <div className={styles['overlaid']} onClick={openAddRateDialog}>
        <div className={styles['overlaid-button']}/>
      </div>}
      <Tabs
        activeKey={tabKey}
        className={classNames(styles['tab-list'])}
        defaultActiveKey={tabKey}
        id="rate-tabs"
        onSelect={onTabSelect}
        // при переключении вкладок сбрасывать изменения в формах
        unmountOnExit>
        {tabs.map(({
          key,
          title,
          rates,
          cityId,
          branchId,
          showActualityWarning,
          innerTitle,
        }, index) =>
          <Tab
            eventKey={key}
            key={key}
            tabClassName={classNames(tabsInDropdownKeys.includes(key) ? 'd-none' : '')}
            title={title}>
            <Box className={styles['no-styles-mobile']} state={index === 0 ? ['box--as-tab-content'] : undefined}>
              <RatesList
                rates={rates}
                cityId={cityId}
                branchId={branchId}
                companyId={companyId}
                showActualityWarning={showActualityWarning}
                isMobileLayout={isMobileLayout}
                listTitle={innerTitle}
                formUnsavedChangesRef={currentTabFormDirtyRef}
              />
            </Box>
          </Tab>)}
        {!!tabsInDropdown.length && <Tab
          tabClassName={classNames("p-0 flex-grow-1 flex-grow-lg-0", styles['tab-height'], isDropdownTabActive && "active")}
          title={
            <Dropdown>
              <Dropdown.Toggle as={NavLink}>{dropdownTitle}</Dropdown.Toggle>
              <Dropdown.Menu>
                {tabsInDropdown.map(({key, title}) =>
                  <Dropdown.Item onClick={() => onTabSelect(key)} key={key}>
                    {title}
                  </Dropdown.Item>)}
                {isMobileLayout &&
                  <Dropdown.Item onClick={openAddRateDialog}>
                    <span className={classNames("d-flex align-items-center text-link")}>
                      <Icon className="me-1" icon={"add_circle_outline"}/>
                      Добавить новый курс валют
                    </span>
                  </Dropdown.Item>}
              </Dropdown.Menu>
            </Dropdown>}>
        </Tab>}
      </Tabs>
    </div>
  
    <ConfirmDialog
      show={!!tabKeyToConfirm}
      onHide={closeTabChangeDialog}
      title={"Перейти к другому курсу валют?"}
      description={"Все несохраненные данные будут утеряны."}
      actionHandler={confirmTabChange}
      actionButtonLabel={"Перейти"}
    />
    
    <RateAddDialog
      show={isAddRateDialog}
      onHide={closeAddRateDialog}
      outputTabs={tabs}
      currentCompanyRestrictions={currentCompanyRestrictions}
      setTabKey={setTabKey}
    />
  </>;
};

export default RatesOutput;
