import React from 'react';
import Modal from "react-bootstrap/Modal";
import {DialogProps} from "./types";

const Dialog: React.FC<DialogProps> = ({
  show,
  onHide,
  title,
  body,
  footer,
}) =>
  <Modal show={show} onHide={onHide} scrollable>
    {title && <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
    </Modal.Header>}
    {body && <Modal.Body>
      {body}
    </Modal.Body>}
    {footer && <Modal.Footer className="justify-content-end">
      {footer}
    </Modal.Footer>}
  </Modal>;

export default Dialog;
