import {AppThunkAction} from "../../../app/store/store";
import {requestExchangeRateDelete, requestExchangeRateEdit, requestExchangeRates} from "../api";
import {getAxiosErrorMessage} from "../../../common/utils/functions";
import {ExtendedExchangeRateForm, RatesListProps} from "../components/RatesOutput/types";
import {ExchangeRateForm} from "../models";
import {Action} from "redux";
import {
  processRatesFailure,
  processRatesRequest,
  processRatesSuccess,
  getRatesFailure,
  getRatesRequest,
  getRatesSuccess,
  updateRatesFailure,
  updateRatesRequest
} from "./slice";
import {RATES_SAVE_SUCCESS_EVENT, RATES_UPDATE_SUCCESS_EVENT} from "../../../app/App";
import {checkIfNeedToUpdate} from "../../config/actions";

type GetRatesParams = {
  isSilent: boolean;
  isAutoPull?: boolean;
  resetEvent?: boolean;
  onSuccess?: () => void;
  onFailure?: () => void;
}

const defaultGetRatesParams: GetRatesParams = {
  isSilent: false,
  isAutoPull: false,
  resetEvent: false,
}

/**
 * Получить курсы валют
 */
export const getRates = ({
  isSilent,
  isAutoPull = false,
  resetEvent,
  onSuccess,
  onFailure,
}: GetRatesParams = defaultGetRatesParams): AppThunkAction<Action> => dispatch => {
  
  if (isSilent) {
    dispatch(updateRatesRequest())
  } else {
    dispatch(getRatesRequest());
  }
  
  requestExchangeRates()
    .then(({data, headers}) => {
      /**
       * Проверить необходимость обновлений, на случай если обновление выходит в ходе работы приложения.
       * При изменении или удалении курсов после успешного запроса также идет запрос getRates,
       * так что при изменении или удалении проверка тоже сработает
       */
      dispatch(checkIfNeedToUpdate(headers));
      dispatch(getRatesSuccess(data || []));
      
      if (onSuccess) {
        onSuccess();
      }
      
      if (isAutoPull) {
        /**
         * Послать актуальные значения курсов напрямую в обработчик события
         */
        window.dispatchEvent(new CustomEvent(RATES_UPDATE_SUCCESS_EVENT, {detail: data}));
      }
      
      if (resetEvent) {
        window.dispatchEvent(new CustomEvent(RATES_SAVE_SUCCESS_EVENT))
      }
    })
    .catch(err => {
      if (onFailure) {
        onFailure();
      }
      
      if (isSilent) {
        dispatch(updateRatesFailure(getAxiosErrorMessage(err)));
        return;
      }
      
      dispatch(getRatesFailure(getAxiosErrorMessage(err)));
    })
}

export type RatesListFormsValues = Record<string, ExtendedExchangeRateForm>;

export type RatesListFormsParams = Pick<RatesListProps, 'cityId' | 'branchId' | 'companyId'> & {
  idsToDelete?: string[];
  onGetRatesSuccess?: () => void;
}

/**
 * Изменить курсы валют
 */
export const editRates = (
  formsValues: RatesListFormsValues,
  {
    cityId,
    branchId,
    companyId,
    idsToDelete = [],
    onGetRatesSuccess,
  }: RatesListFormsParams
): AppThunkAction<Action> => dispatch => {
  
  if (!companyId) {
    return;
  }
  
  dispatch(processRatesRequest());
  
  const forms: ExchangeRateForm[] = [];
  
  Object.entries(formsValues).forEach(([
    id,
    {
      currencyId,
      buyingPrice,
      sellingPrice,
      conditionFrom,
      conditionTo,
    },
  ]) => {
    /**
     * Удаление исключением из списка форм
     */
    if (idsToDelete.includes(id)) {
      return;
    }
    
    forms.push({
      currencyId,
      buyingPrice: buyingPrice || null,
      sellingPrice: sellingPrice || null,
      conditionFrom: conditionFrom || null,
      conditionTo: conditionTo || null,
    })
  })
  
  requestExchangeRateEdit(
    forms,
    {cityId: cityId || undefined, branchId: branchId || undefined, companyId}
  )
    .then(() => {
      dispatch(getRates({
        isSilent: true,
        resetEvent: true,
        onSuccess: () => {
          onGetRatesSuccess?.();
          dispatch(processRatesSuccess());
        },
        onFailure: () => dispatch(processRatesSuccess()),
      }));
      // if (Array.isArray(data)) {
      //   data.forEach(rate => {
      //     if (isMounted.current) {
      //       setData(replaceInData(data, rate));
      //     }
      //   })
      // }
    })
    .catch(err => {
      dispatch(processRatesFailure(getAxiosErrorMessage(err)));
    })
}

export const deleteRates = (
  {cityId, branchId, companyId}: RatesListFormsParams
): AppThunkAction<Action> => dispatch => {
  
  if (!companyId) {
    return;
  }
  
  dispatch(processRatesRequest());
  
  requestExchangeRateDelete({cityId: cityId || undefined, branchId: branchId || undefined, companyId})
    .then(() => {
      dispatch(getRates({
        isSilent: true,
        onSuccess: () => dispatch(processRatesSuccess()),
        onFailure: () => dispatch(processRatesSuccess()),
      }));
    })
    .catch(err => {
      dispatch(processRatesFailure(getAxiosErrorMessage(err)));
    })
}
