import axios, {AxiosRequestConfig, AxiosResponse, RawAxiosRequestHeaders} from "axios";
import queryString from 'query-string';
import {APP_VERSION} from "./appVersion";

export const request = (
  endpoint: string,
  method: AxiosRequestConfig["method"] = "GET",
  data: unknown = null,
  {
    headers = null,
    options = null,
  }: {
    headers?: RawAxiosRequestHeaders | null;
    options?: AxiosRequestConfig | null;
  } = {},
): Promise<AxiosResponse> => {
  const BASE_URL = process.env.NODE_ENV === 'production'
    ? 'https://api-manage.ifin.kz/v1'
    : 'http://api-manage.ifin.loc/v1';
  
  const url = endpoint.includes('http' || 'https')
    ? endpoint
    : `${BASE_URL}${endpoint}`;
  
  return axios({
    url,
    data,
    method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-app-version': APP_VERSION,
      ...headers,
    },
    withCredentials: true,
    ...options,
  })
}

export type APIRequestParameters = {
  // expand?: string;
  // page?: number;
} & Record<string, unknown>

export const addParametersToEndpoint = (
  endpoint: string,
  params: APIRequestParameters = {},
): string => {
  
  const search = queryString.stringify(params, {
    skipNull: true,
    skipEmptyString: true,
  });
  
  return `${endpoint}${search && '?'}${search}`;
}
