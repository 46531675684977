import {UserResponse} from "./models";

const defaultCompanyKey = 'ifin-control-default-company-id';

/**
 * Получение id компании по умолчанию.
 * Если нет сохраненного валидного id в localStorage, то id первой в списке компании.
 */
export const getDefaultCompanyId = ({companies}: UserResponse): number | null => {
  
  const localStorageDefaultId = localStorage.getItem(defaultCompanyKey);
  
  if (!companies) {
    return null;
  }
  
  if (localStorageDefaultId && companies.find(({id}) => id === Number(localStorageDefaultId))) {
    return Number(localStorageDefaultId);
  }
  
  const firstCompanyId = companies[0]?.id;
  
  if (firstCompanyId) {
    localStorage.setItem(defaultCompanyKey, String(companies[0].id))
    return companies[0].id;
  }
  
  return null;
}
