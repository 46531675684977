import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {RateAddDialogOptionProps, RateAddDialogProps} from "./types";
import Button from "react-bootstrap/Button";
import styles from "./RateAddDialog.module.scss";
import Icon from "../../../../common/components/Icon";
import {useAppDispatch, useAppSelector} from "../../../../app/store/hooks";
import {getCities} from "../../../../common/actions/cities";
import classNames from "classnames";
import {citiesErrorSelector, citiesListSelector, citiesLoadingSelector} from "../../../../common/slices/cities";
import {
  branchesErrorSelector,
  branchesListSelector,
  branchesLoadingSelector
} from "../../../../common/slices/branches";
import {getBranches} from "../../../../common/actions/branches";
import {editRates, RatesListFormsParams} from "../../redux/actions";
import Loader from "../../../../common/components/Loader";
import Alert from "react-bootstrap/Alert";
import {currentCompanyIdSelector} from "../../../config/slice";
import Dialog from "../../../../common/components/Dialog";
import {getRateTabKey} from "../../helpers";

/**
 * Диалог добавления курса для компании/города/отделения
 */
const RateAddDialog: React.FC<RateAddDialogProps> = ({
  show,
  onHide,
  outputTabs,
  currentCompanyRestrictions,
  setTabKey,
}) => {

  const dispatch = useAppDispatch();

  const cities = useAppSelector(citiesListSelector);
  const citiesLoading = useAppSelector(citiesLoadingSelector);
  const citiesError = useAppSelector(citiesErrorSelector);

  const branches = useAppSelector(branchesListSelector);
  const branchesLoading = useAppSelector(branchesLoadingSelector);
  const branchesError = useAppSelector(branchesErrorSelector);
  
  const companyId = useAppSelector(currentCompanyIdSelector) || undefined;

  /**
   * map всех доступных городов для оптимизации доступа.
   * Если null, значит все города доступны.
   */
  const allowedCityIdsMap = useMemo(() => {
    if (!currentCompanyRestrictions || !currentCompanyRestrictions.cityIds) {
      return null;
    }

    const result: Record<number, boolean> = {};
    currentCompanyRestrictions.cityIds.forEach(allowedId => {
      result[allowedId] = true;
    })

    return result;
  }, [currentCompanyRestrictions]);

  /**
   * map всех доступных отделений для оптимизации доступа.
   * Если null, значит все города доступны.
   */
  const allowedBranchIdsMap = useMemo(() => {
    if (!currentCompanyRestrictions || !currentCompanyRestrictions.branchIds) {
      return null;
    }

    const result: Record<number, boolean> = {};
    currentCompanyRestrictions.branchIds.forEach(allowedId => {
      result[allowedId] = true;
    })

    return result;
  }, [currentCompanyRestrictions]);
  
  /**
   * Можно создать курс для города, если массив городов не пуст
   * (в RatesOutput уже есть эта проверка, но тут она повторяется для надежности),
   * и если не заданы ограничения отделений.
   * При наличии ограничений по отделениям, можно создавать только курсы для отделений, для городов нельзя.
   */
  const canChooseCityMode = (!allowedCityIdsMap || Object.keys(allowedCityIdsMap).length) && !allowedBranchIdsMap;
  /**
   * Можно создать курс для отделения, если массив отделений не пуст
   * (в RatesOutput уже есть эта проверка, но тут она повторяется для надежности).
   */
  const canChooseBranchMode = !allowedBranchIdsMap || Object.keys(allowedBranchIdsMap).length;
  
  const canOnlyChooseBranchMode = !canChooseCityMode && canChooseBranchMode;
  
  /**
   * Если нельзя создавать курс для города, но можно для отделения,
   * иногда пропустить выбор города и запросить список отделений сразу.
   */
  const defaultCityId: number | null | CityIdToBypassConstant = canOnlyChooseBranchMode
    ? !allowedCityIdsMap
      /**
       * Если ограничений по городу нет, пропустить выбор города и запросить список отделений без cityId.
       */
      ? CITY_TO_BYPASS_ID
      /**
       * Если ограничения есть, но разрешен только один город,
       * пропустить выбор города и запросить список отделений с этим одним cityId.
       */
      : Object.keys(allowedCityIdsMap).length === 1
        ? Number(Object.keys(allowedCityIdsMap)[0])
        /**
         * Если разрешенных городов несколько, нужно показывать их список
         */
        : null
    : null;
  
  /**
   * Запрос списка отделений сразу при необходимости
   */
  useEffect(() => {
    if (show && defaultCityId) {
      dispatch(getBranches(companyId, defaultCityId === CITY_TO_BYPASS_ID ? undefined : defaultCityId))
    }
  }, [companyId, defaultCityId, dispatch, show]);
  
  /**
   * Если нельзя создавать курс для города, но можно для отделения,
   * и при этом городов несколько и defaultCityId остается null, запросить список городов.
   */
  useEffect(() => {
    if (show && canOnlyChooseBranchMode && !defaultCityId) {
      dispatch(getCities(companyId))
    }
  }, [canOnlyChooseBranchMode, companyId, defaultCityId, dispatch, show]);
  
  /**
   * Можно создать общий курс для компании, если список городов и отделений не ограничен
   */
  const canChooseGeneralMode = !allowedCityIdsMap && !allowedBranchIdsMap;
  
  /**
   * Добавляются три пустых курса доллара, евро и рубля
   */
  const addRates = useCallback((params: Omit<RatesListFormsParams, 'companyId'>) => {
    dispatch(editRates(
      {
        id1: {
          id: 'id1',
          currencyId: 'USD',
          buyingPrice: '',
          sellingPrice: '',
          conditionFrom: '',
          conditionTo: '',
          isWholesale: false,
          conditionOrder: 0,
        },
        id2: {
          id: 'id2',
          currencyId: 'EUR',
          buyingPrice: '',
          sellingPrice: '',
          conditionFrom: '',
          conditionTo: '',
          isWholesale: false,
          conditionOrder: 0,
        },
        id3: {
          id: 'id3',
          currencyId: 'RUB',
          buyingPrice: '',
          sellingPrice: '',
          conditionFrom: '',
          conditionTo: '',
          isWholesale: false,
          conditionOrder: 0,
        },
      },
      {
        ...params,
        companyId: companyId || null,
        onGetRatesSuccess: () => setTabKey(getRateTabKey(params.cityId, params.branchId))
      },
    ));
  }, [companyId, dispatch, setTabKey]);

  type RateAddModes = 'city' | 'branch' | null;

  type Option = RateAddDialogOptionProps & {
    id: string;
    modeId: RateAddModes;
  }

  const modeOptions = useMemo(() => {
    const options: Option[] = []

    if (canChooseBranchMode) {
      options.push({
        id: 'branch',
        modeId: 'branch',
        title: 'Персональный курс для отделения',
        onClick: () => {
          setMode('branch');
          dispatch(getCities(companyId));
        },
        subtitle: 'Персональный курс валют, действующий только в выбранном отделении',
      })
    }

    if (canChooseCityMode) {
      options.push({
        id: 'city',
        modeId: 'city',
        title: 'Общий курс валют для города',
        onClick: () => {
          setMode('city');
          dispatch(getCities(companyId));
        },
        subtitle: 'Общий курс валют для всех отделений в выбранном городе',
      })
    }

    if (canChooseGeneralMode) {
      options.push({
        id: 'general',
        modeId: null,
        title: 'Общий курс валют компании',
        onClick: () => {
          addRates({
            cityId: null,
            branchId: null,
          })
  
          /**
           * Вариант closeAndReset, помещенный сюда для избежания цикличности упоминаний.
           * Если выбран общий курс, то ограничений нет и все сбрасывается в null.
           * @see closeAndReset
           */
          onHide();
          setMode(null);
          setCityId(null);
        },
        subtitle: 'Общий курс валют для всех отделений компании',
        disabled: !!outputTabs.find(tab => tab.cityId === null && tab.branchId === null),
      })
    }

    return options;
  }, [addRates, canChooseBranchMode, canChooseCityMode, canChooseGeneralMode, companyId, dispatch, onHide, outputTabs]);
  
  /**
   * Если опция mode одна, она сразу выбирается.
   * (На данный момент, учитывая все комбинации прав, единственный mode,
   *  который может автоматически выбраться таким образом - branch.)
   */
  const defaultMode = modeOptions.filter(({disabled}) => !disabled).length === 1
    ? modeOptions[0].modeId
    : null;
  
  /**
   * Тип добавления: для чего добавляется курс
   */
  const [mode, setMode] = useState<RateAddModes>(defaultMode);
  const [cityId, setCityId] = useState<number | null | CityIdToBypassConstant>(defaultCityId);
  
  /**
   * Закрывать диалог сразу, если:
   *  - мы в выборе mode
   *  - возможен только один mode - branch и:
   *    - выбора города нет и вернуться к нему нельзя
   *    - выбор городов есть и мы в выборе городов
   */
  const footerButtonAction: 'close' | 'back' = !mode || (defaultMode && (defaultCityId || !cityId))
    ? 'close'
    : 'back';
  
  const closeAndReset = useCallback(() => {
    onHide();
    setMode(defaultMode);
    setCityId(defaultCityId);
  }, [defaultCityId, defaultMode, onHide]);
  
  const closeOrGoBack = useCallback(() => {
    if (footerButtonAction === 'close') {
      closeAndReset();
      return;
    }
  
    /**
     * "Назад" при выборе города - вернуться к выбору mode
     */
    if (mode === 'city' || (mode === 'branch' && !cityId)) {
      setMode(null);
      setCityId(null);
      return;
    }
  
    /**
     * "Назад" при выборе отделения - вернуться к выбору города
     */
    setCityId(null);
  }, [cityId, closeAndReset, footerButtonAction, mode])

  const content = useMemo(() => {
    type Option = RateAddDialogOptionProps & {id: string};

    /**
     * Начальный список, если mode добавляемого курса не выбран
     */
    if (!mode) {
      if (!modeOptions.length) {
        return <Alert className="mt-2 mx-3" variant="danger">
          Нет доступа
        </Alert>
      }

      /**
       * Автоматический выбор опции, если для выбора доступна только одна
       */
      // if (modeOptions.filter(({disabled}) => !disabled).length === 1) {
      //   modeOptions.filter(({disabled}) => !disabled)[0].onClick();
      //   return <Loader className="mt-2 mx-3"/>
      // }

      return <>
        {modeOptions.map(({id, title, onClick, subtitle, disabled}) =>
          <RateAddDialogOption
            key={id}
            title={title}
            onClick={onClick}
            subtitle={subtitle}
            disabled={disabled}
          />)}
      </>
    }

    if (mode === 'city' || (mode === 'branch' && !cityId)) {
      if (citiesLoading) {
        return <Loader className="mt-2 mx-3"/>
      }

      if (citiesError) {
        return <Alert className="mt-2 mx-3" variant="danger">
          {citiesError}
        </Alert>
      }

      const displayedCities = allowedCityIdsMap
        ? cities.filter(({id}) => allowedCityIdsMap[id])
        : cities;

      const cityOptions: Option[] = displayedCities.map(({
        id,
        name,
      }) => ({
        id: String(id),
        title: name,
        onClick: () => {
          if (mode === 'city') {
            addRates({
              cityId: id,
              branchId: null,
            })
            closeAndReset();
            return;
          }

          setCityId(id);
          dispatch(getBranches(companyId, id));
        },
        disabled: mode === 'city' && !!outputTabs.find(tab => tab.cityId === id && tab.branchId === null),
      }));

      // if (cityOptions.filter(({disabled}) => !disabled).length === 1) {
      //   cityOptions.filter(({disabled}) => !disabled)[0].onClick();
      //   return <Loader className="mt-2 mx-3"/>
      // }

      return <div className={styles.scrollable}>
        {cityOptions.map(({id, title, onClick, disabled}) =>
          <RateAddDialogOption
            key={id}
            title={title}
            onClick={onClick}
            disabled={disabled}
          />)}
      </div>
    }

    if (branchesLoading) {
      return <Loader className="mt-2 mx-3"/>
    }

    if (branchesError) {
      return <Alert className="mt-2 mx-3" variant="danger">
        {branchesError}
      </Alert>
    }

    const displayedBranches = allowedBranchIdsMap
      ? branches.filter(({id}) => allowedBranchIdsMap[id])
      : branches;

    if (!displayedBranches.length) {
      return <div className="mx-3 mt-3">
        Для выбранного города отделений не найдено
      </div>
    }

    const branchOptions: Option[] = displayedBranches.map(({
      id,
      name,
      address,
      // cityId берется отсюда, потому что при некоторых комбинациях прав cityId отсутствует в state
      cityId,
    }) => ({
      id: String(id),
      title: name || "Отделение",
      subtitle: address,
      onClick: () => {
        addRates({
          cityId,
          branchId: id,
        })
        closeAndReset();
      },
      disabled: !!outputTabs.find(tab => tab.cityId === cityId && tab.branchId === id),
    }));

    return <div className={styles.scrollable}>
      {branchOptions.map(({id, title, subtitle, onClick, disabled}) =>
        <RateAddDialogOption
          key={id}
          title={title}
          subtitle={subtitle}
          onClick={onClick}
          disabled={disabled}
        />)}
    </div>
  }, [addRates, allowedBranchIdsMap, allowedCityIdsMap, branches, branchesError, branchesLoading, cities, citiesError, citiesLoading, cityId, closeAndReset, companyId, dispatch, mode, modeOptions, outputTabs]);

  return <Dialog
    show={show}
    onHide={onHide}
    title={"Добавление нового курса валют"}
    body={content}
    footer={<Button onClick={closeOrGoBack} variant={"primary"}>
      {footerButtonAction === 'close' ? "Отмена" : "Назад"}
    </Button>}
  />;
};

/**
 * Опция списка в диалоге добавления курса
 */
const RateAddDialogOption: React.FC<RateAddDialogOptionProps> = ({
  title,
  subtitle,
  onClick,
  disabled = false,
}) =>
  <div
    className={classNames(styles['option'], disabled && styles['option--disabled'])}
    onClick={!disabled ? onClick : undefined}>
    <div className="flex-grow-1">
      <div>{title}</div>
      <div className={classNames("mt-1", styles['option-subtitle'])}>
        {subtitle}
      </div>
    </div>
    <Icon className="ms-2" icon={"navigate_next"}/>
  </div>

/** cityId, используемый на фронте, чтобы обозначить, что город при запросе отделений указывать не нужно */
type CityIdToBypassConstant = '___CITY_ID_TO_BYPASS';
const CITY_TO_BYPASS_ID: CityIdToBypassConstant = '___CITY_ID_TO_BYPASS';

export default RateAddDialog;
