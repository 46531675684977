export type Breakpoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

/**
 * Минимумы ширины экрана для каждой точки разбития верстки
 * Соответствует bootstrap/_variables -> $grid-breakpoints
 */
export const gridBreakpoints: Record<Breakpoints, number> = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
}
