import React from 'react';
import styles from "./LayoutTopAlert.module.scss";
import {LayoutTopAlertProps} from "./types";
import classNames from "classnames";

/**
 * Предупреждение строкой во всю ширину, оформленное как Alert--danger
 * По умолчанию является предупреждением об устаревшей версии
 */
const LayoutTopAlert: React.FC<LayoutTopAlertProps> = ({
  message,
  className,
}) => {
  
  const displayedMessage = message || 'Используется устаревшая версия приложения. Необходимо обновить страницу.';
  
  return (
    <div className={classNames(styles['top-alert'], styles['top-alert--default'], className)}>
      {displayedMessage}
      &nbsp;
      <span className='fw-bold' role={'button'} onClick={() => window.location.reload()}>Обновить</span>
    </div>
  );
};

export default LayoutTopAlert;
