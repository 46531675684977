import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Field, FieldProps, Form, Formik, useFormikContext} from "formik";
import classNames from "classnames";
import styles from "./RatesList.module.scss";
import {NumericFormat} from "react-number-format";
import TextInput from "../../../../../common/components/TextInput";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {formatDateAsString, generateUUID, getDateFromString} from "../../../../../common/utils/functions";
import _groupBy from "lodash.groupby";
import {compareExchangeRateForms, getCompareCurrencyRatesEntries} from "../../../helpers";
import Alert from "react-bootstrap/Alert";
import {compareAsc} from "date-fns";
import WarningIcon from "../../../../../common/components/Icon/WarningIcon";
import {useAppDispatch, useAppSelector} from "../../../../../app/store/hooks";
import {deleteRates, editRates, RatesListFormsValues} from "../../../redux/actions";
import {
  clearRatesEditError,
  clearRatesUpdateError,
  ratesActionErrorSelector,
  ratesActionLoadingSelector,
  ratesUpdateErrorSelector,
} from "../../../redux/slice";
import {dictionaryCurrenciesListSelector} from "../../../../../common/slices/dictionaryCurrencies";
import {RatesListProps} from "./types";
import ConfirmDialog from "../../../../../common/components/Dialog/ConfirmDialog";
import RatesListCurrenciesDialog from "./RatesListCurrenciesDialog";
import IconButton from "../../../../../common/components/Icon/IconButton";
import {ExtendedExchangeRateForm} from "../types";
import {ExchangeRateResponse} from "../../../models";
import Icon from "../../../../../common/components/Icon";
import _isEqual from "lodash.isequal";
import {RATES_SAVE_SUCCESS_EVENT, RATES_UPDATE_SUCCESS_EVENT} from "../../../../../app/App";
import Loader from "../../../../../common/components/Loader";
import {usePrevious} from "../../../../../common/utils/usePrevious";

/**
 * Список курсов для одного сочетания id компании, города и отделения
 */
const RatesList: React.FC<RatesListProps> = (props) => {
  
  const {
    rates,
    cityId,
    branchId,
    companyId,
  } = props;
  
  const dispatch = useAppDispatch();
  
  /**
   * Изначальные значения формы
   */
  const initialValues = useMemo(() => getRatesListFormInitialValues(rates), [rates]);
  
  /**
   * Удаление курса происходит исключением формы курса из списка посылаемых.
   * При нажатии на удаление курс убирается из показа и запоминается здесь.
   */
  const [rateIdsToDelete, setRateIdsToDelete] = useState<string[]>([]);
  
  const addRateIdsToDeletionList = useCallback((input: string | string[]) => {
    if (Array.isArray(input)) {
      setRateIdsToDelete(prevState => {
        return [...prevState, ...input.filter(id => !prevState.includes(id))];
      })
      
      return;
    }
    
    setRateIdsToDelete(prevState => {
      if (prevState.includes(input)) {
        return prevState;
      }
      
      return [...prevState, input];
    })
  }, []);
  
  return <Formik
    initialValues={initialValues}
    onSubmit={values => {
      dispatch(editRates(
        values,
        {
          cityId,
          branchId,
          companyId,
          idsToDelete: rateIdsToDelete,
        }
      ))
  }}>
    <RatesListForm
      {...props}
      rateIdsToDelete={rateIdsToDelete}
      addRateIdsToDeletionList={addRateIdsToDeletionList}
    />
  </Formik>
}

const emptyRateForm = {
  currencyId: '',
  buyingPrice: '',
  sellingPrice: '',
  conditionFrom: '',
  conditionTo: '',
  isWholesale: false,
}

export type RatesListFormProps = RatesListProps & {
  rateIdsToDelete: string[];
  addRateIdsToDeletionList: (input: string | string[]) => void;
}

/**
 * Форма списка курсов.
 * В отдельном компоненте, чтобы использовать useEffect-ы, зависящие от хелперов Formik
 */
const RatesListForm: React.FC<RatesListFormProps> = ({
  rates,
  cityId,
  branchId,
  companyId,
  showActualityWarning,
  isMobileLayout,
  listTitle,
  formUnsavedChangesRef,
  rateIdsToDelete,
  addRateIdsToDeletionList,
}) => {
  
  const dispatch = useAppDispatch();
  
  const dictionaryCurrencies = useAppSelector(dictionaryCurrenciesListSelector);
  
  const actionLoading = useAppSelector(ratesActionLoadingSelector);
  
  const actionError = useAppSelector(ratesActionErrorSelector);
  const updateError = useAppSelector(ratesUpdateErrorSelector);
  
  /**
   * Диалог полного удаления всех курсов текущей вкладки.
   * После подтверждения курсы удаляются сразу запросом на сервер.
   */
  const [isListDeleteConfirm, setIsListDeleteConfirm] = useState(false);
  
  const openListDeleteConfirmDialog = useCallback(() => setIsListDeleteConfirm(true), []);
  const closeListDeleteConfirmDialog = useCallback(() => setIsListDeleteConfirm(false), []);
  
  /**
   * Полное удаление
   */
  const deleteList = useCallback(() => {
    closeListDeleteConfirmDialog();
    dispatch(deleteRates({cityId, branchId, companyId}));
  }, [branchId, cityId, closeListDeleteConfirmDialog, companyId, dispatch]);
  
  /**
   * id курсов, удаление которых нужно подтвердить.
   * Если массив не пуст, показывается диалог подтверждения.
   * После подтверждения курсы удаляются локально, запрос посылается только если нажата кнопка Обновить курсы.
   */
  const [rateIdsAboutToBeDeleted, setRateIdsAboutToBeDeleted] = useState<string[]>([]);
  
  /**
   * Название и контент диалога подтверждения удаления курсов.
   * Зависят от того, какие курсы удаляются и откуда был вызван диалог подтверждения.
   */
  const [rateDeleteConfirmDialogTitle, setRateDeleteConfirmDialogTitle] = useState<string | null>(null);
  const [rateDeleteConfirmDialogDesc, setRateDeleteConfirmDialogDesc] = useState<string | null>(null);
  
  type OpenDeleteConfirmDialogParams = {
    input: string | string[];
    displayedIds: string[];
    dialogTitle: string;
    dialogDesc?: string;
  }
  
  /**
   * Запросить подтверждение удаления курсов.
   * Если удаляется последний(ие) курс(ы), запросить подтверждение полного удаления
   */
  const openDeleteConfirmDialog = useCallback(({
    input,
    displayedIds,
    dialogTitle,
    dialogDesc,
  }: OpenDeleteConfirmDialogParams) => {
    if (Array.isArray(input)) {
      /**
       * Проверка полного удаления
       */
      if (displayedIds.length === input.length) {
        openListDeleteConfirmDialog();
        return;
      }
      
      setRateDeleteConfirmDialogTitle(dialogTitle);
      if (dialogDesc) {
        setRateDeleteConfirmDialogDesc(dialogDesc);
      }
      
      setRateIdsAboutToBeDeleted(prevState =>
        [...prevState, ...input.filter(id => !prevState.includes(id))]);
      
      return;
    }
    
    /**
     * Проверка полного удаления
     */
    if (displayedIds.length === 1 && displayedIds[0] === input) {
      openListDeleteConfirmDialog();
      return;
    }
  
    setRateDeleteConfirmDialogTitle(dialogTitle);
    if (dialogDesc) {
      setRateDeleteConfirmDialogDesc(dialogDesc);
    }
    
    setRateIdsAboutToBeDeleted(prevState => {
      if (prevState.includes(input)) {
        return prevState;
      }
      
      return [...prevState, input];
    })
  }, [openListDeleteConfirmDialog]);
  
  const closeDeleteConfirmDialog = useCallback(() => {
    setRateIdsAboutToBeDeleted([]);
    setRateDeleteConfirmDialogTitle(null);
    setRateDeleteConfirmDialogDesc(null);
  }, []);
  
  /**
   * Самое раннее время обновления из всех показываемых курсов
   * (берется согласно rates, т.е. если с сервера пришли курсы с более новым временем, оно показывается,
   *  несмотря на то, что другие данные пришедших с сервера курсов не показываются).
   * Также из rates берется статус актуальности (не здесь, в компонентах выше по иерархии).
   */
  const earliestRefreshTime = useMemo(() => {
    const earliestDate = rates
      .filter(({id}) => !rateIdsToDelete.includes(String(id)))
      .map(({refreshTime}) => getDateFromString(refreshTime))
      .sort(compareAsc)[0];
    
    if (!earliestDate) {
      return '';
    }
    
    return formatDateAsString(earliestDate);
  }, [rateIdsToDelete, rates]);
  
  /**
   * Имея справочник валют, можно получить каррированную функцию сравнения валют
   */
  const compareCurrencyObjectEntriesByIndex = useMemo(() =>
    getCompareCurrencyRatesEntries(dictionaryCurrencies), [dictionaryCurrencies])
  
  /**
   * Диалог добавления валют (только на мобильных)
   */
  const [isCurrencyAddDialog, setIsCurrencyAddDialog] = useState(false);
  
  const openCurrencyAddDialog = useCallback(() => setIsCurrencyAddDialog(true), []);
  const closeCurrencyAddDialog = useCallback(() => setIsCurrencyAddDialog(false), []);
  
  const {
    values,
    setFieldValue,
    resetForm,
    setValues,
    initialValues,
  } = useFormikContext<RatesListFormsValues>();
  
  /**
   * Последние сохраненные значения.
   * Предполагается, что именно такие значения находятся на сервере сейчас.
   * initialValues не совсем подходит: не всегда обновляется после сохранения,
   * соответственно FormikProps->dirty тоже не подходит, так как сравнивает с initialValues.
   * Вместо dirty используется вручную вычисляемый formUnsavedChangesRef.
   */
  const [lastSavedValues, setLastSavedValues] = useState(initialValues);
  
  /**
   * Актуальное значение наличия несохраненных изменений, см. выше
   */
  useEffect(() => {
    formUnsavedChangesRef.current = !_isEqual(values, lastSavedValues);
  }, [formUnsavedChangesRef, lastSavedValues, values]);
  
  /**
   * При сохранении курсов обновить последние сохраненные и отметить, что текущих несохраненных изменений нет.
   */
  useEffect(() => {
    const updateLastSaved = () => {
      setLastSavedValues(values);
      formUnsavedChangesRef.current = false;
    }
  
    window.addEventListener(RATES_SAVE_SUCCESS_EVENT, updateLastSaved);
  
    return () => {
      window.removeEventListener(RATES_SAVE_SUCCESS_EVENT, updateLastSaved);
    }
  }, [formUnsavedChangesRef, values]);
  
  /** статус показа уведомления о том, что на сервере есть изменения, которые не отражены здесь */
  const [showIntervalUpdateChange, setShowIntervalUpdateChange] = useState(false);
  
  /** реф таймаута отдельно, чтобы функция return хука обработки пуллинга не сбрасывала установленный ею же таймаут */
  const t = useRef<NodeJS.Timeout | null>(null);
  
  useEffect(() => {
    /**
     * Обработать пришедшие с сервера данные
     * (другими способами получить актуальные данные в замыкании сложнее)
     */
    const handleUpdate = (e: CustomEvent<ExchangeRateResponse[]>) => {
  
      if (!Array.isArray(e.detail)) {
        return;
      }
      
      t.current = setTimeout(() => {
        /** из всех курсов выбрать те, что подходят данной вкладке */
        const newRates = e.detail
          .filter(rate => rate.companyId === companyId && rate.cityId === cityId && rate.branchId === branchId);
        const newValues = getRatesListFormInitialValues(newRates);
  
        /**
         * Если курсы с сервера, полученные интервальным обновлением, отличаются от последних сохраненных здесь, то:
         */
        if (_isEqual(newValues, lastSavedValues)) {
          return;
        }
  
        /**
         *  - если в форме есть изменения: показать уведомление, что курсы изменились
         */
        if (formUnsavedChangesRef.current) {
          setShowIntervalUpdateChange(true);
          return;
        }
  
        /**
         *  - если в форме нет изменений: заменить значения на новые
         */
        setValues(newValues);
        setLastSavedValues(newValues);
      }, 500);
    }
    
    window.addEventListener(RATES_UPDATE_SUCCESS_EVENT, handleUpdate as EventListener);
    
    return () => {
      window.removeEventListener(RATES_UPDATE_SUCCESS_EVENT, handleUpdate as EventListener);
      // очистка таймаута в хуке ниже
    }
  }, [branchId, cityId, companyId, formUnsavedChangesRef, lastSavedValues, resetForm, setValues]);
  
  useEffect(() => {
    return () => {
      if (t.current) {
        clearTimeout(t.current)
      }
    }
  }, []);
  
  const previousCompanyId = usePrevious(companyId);
  
  /**
   * Если при переключении компаний не изменяется ключ вкладки, могут показываться данные предыдущей компании.
   * Этот эффект предотвращает этот баг
   */
  useEffect(() => {
    if (companyId !== previousCompanyId) {
      const newValues = getRatesListFormInitialValues(rates);
      
      setValues(newValues);
      setLastSavedValues(newValues);
    }
  }, [companyId, previousCompanyId]);
  
  const displayedValues: typeof initialValues = {};
  Object.entries(values).forEach(([id, rate]) => {
    if (!rateIdsToDelete.includes(id)) {
      displayedValues[id] = rate;
    }
  })
  
  const displayedIds = Object.keys(displayedValues);
  
  const groupedByCurrency = _groupBy(displayedValues, 'currencyId');
  
  const usedCurrencyIds: string[] = Object.keys(groupedByCurrency);
  
  const usedCurrencies = dictionaryCurrencies.filter(({id}) => usedCurrencyIds.includes(id));
  const unusedCurrencies = dictionaryCurrencies.filter(({id}) => !usedCurrencyIds.includes(id));
  
  type AddRateParams = {
    currencyId: string;
    isWholesale?: boolean;
    conditionOrder?: number;
  }
  
  const addRate = ({currencyId, isWholesale = false, conditionOrder}: AddRateParams) => {
    const id = generateUUID();
    setFieldValue(id, {
      ...emptyRateForm,
      id,
      currencyId,
      isWholesale,
      conditionOrder: isWholesale
        ? conditionOrder ?? 1
        : 0,
    })
  }
  
  const removeRates = (input: string[] | null = null) => {
    
    const idsToProcess = Array.isArray(input)
      ? input
      : rateIdsAboutToBeDeleted;
    
    if (!idsToProcess.length) {
      return;
    }
    
    idsToProcess.forEach(rateId => {
      // setFieldValue(rateId, {
      //   ...emptyRateForm,
      //   id: rateId,
      // })
      const rateToDelete = values[rateId];
      
      /**
       * Если удаляется оптовый курс с самым большим значением "от" ("до" не ограничено),
       * сделать неограниченное "до" курсу с предпоследним по величине значением "от"
       */
      if (rateToDelete.isWholesale && !rateToDelete.conditionTo) {
        const rateToModify = Object.entries(values)
          .find(([, r]) => r.currencyId === rateToDelete.currencyId && r.conditionTo === rateToDelete.conditionFrom);
        
        if (rateToModify) {
          setFieldValue(rateToModify[0], {...rateToModify[1], conditionTo: ''});
        }
      }
      
      setFieldValue(rateId, undefined);
    })
    
    addRateIdsToDeletionList(idsToProcess);
    closeDeleteConfirmDialog();
  }
  
  const getCurrencyRateIds = (currencyId: string): string[] =>
    groupedByCurrency[currencyId]?.map(({id}) => id) || [];
  
  /**
   * Применить изменения списка валют
   */
  const applyCurrencyChanges = (nextCurrencyIds: string[]) => {
    /** id, которые были в списке, но были исключены, нужно удалить */
    const currenciesToDelete = usedCurrencyIds.filter(id => !nextCurrencyIds.includes(id));
    
    const rateIdsToDelete: string[] = [];
    currenciesToDelete.forEach(currencyId => {
      rateIdsToDelete.push(...getCurrencyRateIds(currencyId));
    })
    
    removeRates(rateIdsToDelete);
    
    /** id, которые не были в списке, но появились, нужно добавить */
    const currenciesToAdd = nextCurrencyIds.filter(id => !usedCurrencyIds.includes(id));
    
    currenciesToAdd.forEach(currencyId => {
      addRate({currencyId});
    })
    
    closeCurrencyAddDialog();
  }
  
  /**
   * Валюты и связанные курсы, отсортированные по индексу валюты в справочнике валют
   */
  const sortedCurrencyObjects = Object.entries(groupedByCurrency).sort(compareCurrencyObjectEntriesByIndex);
  
  /**
   * Пропсы, общие для компонентов ввода чисел
   */
  const sharedNumericInputProps = useMemo(() => ({
    allowNegative: false,
    decimalSeparator: '.',
    allowedDecimalSeparators: [',', '.'],
    thousandSeparator: ' ',
    customInput: TextInput,
    disabled: actionLoading,
    // inputMode='numeric' не работает в Safari
    inputMode: 'decimal' as const,
  }), [])
  
  return <Form>
    <Row xs={9} lg={12}>
      <Col lg={9}>
        {usedCurrencyIds.length
          ? <>
            <div className="d-table w-100">
              <div className={classNames(styles['padded-row'], "d-none d-lg-table-row")}>
                <div className="d-table-cell pt-0 pb-2">Валюта</div>
                <div className={classNames("d-table-cell pt-0 pb-2", styles['padded-row-range'])}/>
                <div className={classNames("d-table-cell pt-0 pb-2", styles['padded-row-input-middle'])}>Покупка</div>
                <div className={classNames("d-table-cell pt-0 pb-2", styles['padded-row-input-last'])}>Продажа</div>
              </div>
              {sortedCurrencyObjects.map(([currencyId, rates]) => {
              
                const currency = dictionaryCurrencies.find(({id}) => id === currencyId);
                /** для золота отображается "грамм", все остальные валюты показываются как код */
                const displayedCurrencyId = currencyId === 'GOLD'
                  ? 'грамм'
                  : currencyId;
  
                /**
                 * все курсы для этой валюты, отсортированные по conditionFrom по возрастанию
                 */
                const sortedRatesForThisCurrency: ExtendedExchangeRateForm[] = rates
                  .filter(({id}) => !rateIdsToDelete.includes(id))
                  .sort(compareExchangeRateForms);
                
                const wholesaleRates = sortedRatesForThisCurrency.filter(rate => rate.isWholesale);
                
                const hasWholesaleRates = !!wholesaleRates.length;
                /**
                 * Самое большее текущее или бывшее значение "от" курса,
                 * чтобы добавляемые курсы были всегда после существующих
                 */
                const highestWholesaleOrder = hasWholesaleRates
                  ? Math.max(...wholesaleRates.map(({conditionFrom, conditionOrder}) => Math.max(conditionFrom || 0, conditionOrder)))
                  : 0;
                
                type RenderPartFunction = (form: ExtendedExchangeRateForm, index: number) => JSX.Element;
              
                const renderWholesaleFormPart: RenderPartFunction = (
                  {id, isWholesale, conditionFrom},
                  index,
                ) => {
  
                  const key = `whole-${id}`;
  
                  if (!isWholesale) {
                    /**
                     * Показывать кнопку добавления оптового курса,
                     * если этот курс розничный и нет оптовых курсов для этой валюты
                     */
                    const showWholesaleRateAddButton = !isMobileLayout && !hasWholesaleRates;
    
                    return <div
                      className={classNames(
                        "d-flex flex-column justify-content-center flex-grow-1 flex-lg-grow-0",
                        styles['input-height'],
                      )}
                      key={key}>
                      Розничный курс
                      {showWholesaleRateAddButton && <Button
                        variant='link'
                        onClick={() => addRate({currencyId, isWholesale: true, conditionOrder: 1})}
                        disabled={actionLoading}>
                        Установить оптовые курсы
                      </Button>}
                    </div>
                  }
  
                  return <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0 mt-0 mt-lg-2_5" key={key}>
                    от
                    <Field
                      name={`${id}.conditionFrom`}
                      validate={() => {
                        if (!conditionFrom) {
                          return 'Не заполнены интервалы оптового курса валют';
                        }
  
                        /**
                         * Если есть курс раньше по порядку, проверить, что его "от" меньше
                         */
                        if (sortedRatesForThisCurrency[index - 1] && sortedRatesForThisCurrency[index - 1].conditionFrom >= conditionFrom) {
                          return 'Наложение курсов';
                        }
                        
                        /**
                         * Аналогично, если есть курс позже по порядку, проверить, что его "от" больше
                         */
                        if (sortedRatesForThisCurrency[index + 1] && sortedRatesForThisCurrency[index + 1].conditionFrom <= conditionFrom) {
                          return 'Наложение курсов';
                        }
                      }}>
                      {({meta, ...props}: FieldProps) =>
                        <NumericFormat
                          {...props}
                          id={`${id}.conditionFrom`}
                          name={`${id}.conditionFrom`}
                          onValueChange={({floatValue}) => {
                            setFieldValue(`${id}.conditionFrom`, floatValue ?? '');
            
                            /**
                             * После изменения "от" оптового курса нужно также изменить "до" предыдущего курса
                             */
                            if (sortedRatesForThisCurrency[index - 1]) {
                              setFieldValue(`${sortedRatesForThisCurrency[index - 1].id}.conditionTo`, floatValue ?? '');
                            }
                          }}
                          value={values[id]?.conditionFrom || ''}
                          onBlur={props.field.onBlur}
                          decimalScale={currency?.minorUnit}
                          className={classNames("mx-1 ms-sm-3 me-sm-2", styles['fixed-width'])}
                          {...sharedNumericInputProps}
                        />}
                    </Field>
                    {displayedCurrencyId}
                  </div>;
                }
              
                const renderBuyingPriceFormPart: RenderPartFunction = (
                  {id, isWholesale},
                ) =>
                  <Field
                    key={`buy-${id}`}
                    name={`${id}.buyingPrice`}
                    // валидация курсов покупки и продажи на данный момент не используется
                    /*validate={() => {
                      if (isWholesale) {
                        if (!buyingPrice) {
                          return 'Не введен курс покупки';
                        }
  
                        /!**
                         * Если есть курс раньше по порядку, проверить, что его курс покупки больше
                         *!/
                        if (sortedRatesForThisCurrency[index - 1] && typeof sortedRatesForThisCurrency[index - 1].buyingPrice === "number" && sortedRatesForThisCurrency[index - 1].buyingPrice >= buyingPrice) {
                          return 'Оптовый курс покупки не может быть меньше значения предыдущего интервала';
                        }
                      }
  
                      if (sellingPrice && sellingPrice <= buyingPrice) {
                        return 'Курс покупки не может быть больше курса продажи';
                      }
                      
                      return undefined;
                    }}*/>
                    {({meta, ...props}: FieldProps) =>
                      <NumericFormat
                        {...props}
                        id={`${id}.buyingPrice`}
                        onValueChange={({floatValue}) =>
                          setFieldValue(`${id}.buyingPrice`, floatValue ?? '')}
                        value={values[id]?.buyingPrice || ''}
                        onBlur={props.field.onBlur}
                        decimalScale={currency?.minorUnit}
                        className={classNames(
                          isWholesale && "mt-0 mt-lg-2_5",
                          "ms-2 ms-lg-0",
                          styles['fixed-width']
                        )}
                        wrapperClassName="flex-shrink-0"
                        {...sharedNumericInputProps}
                      />}
                  </Field>
              
                const renderSellingPriceFormPart: RenderPartFunction = (
                  {id, isWholesale},
                ) =>
                  <div className={classNames("d-flex align-items-center flex-shrink-0 ms-2 ms-lg-0", isWholesale && "mt-0 mt-lg-2_5")} key={`sell-${id}`}>
                    <Field
                      name={`${id}.sellingPrice`}
                      // валидация курсов покупки и продажи на данный момент не используется
                      /*validate={() => {
                        if (isWholesale) {
                          if (!sellingPrice) {
                            return 'Не введен курс продажи';
                          }
  
                          /!**
                           * Если есть курс раньше по порядку, проверить, что его курс покупки больше
                           *!/
                          if (sortedRatesForThisCurrency[index - 1] && typeof sortedRatesForThisCurrency[index - 1].sellingPrice === "number" && sortedRatesForThisCurrency[index - 1].sellingPrice < sellingPrice) {
                            return 'Оптовый курс продажи не может быть больше значения предыдущего интервала';
                          }
                        }
  
                        if (sellingPrice && sellingPrice <= buyingPrice) {
                          return 'Курс покупки не может быть больше курса продажи'
                        }
                      }}*/>
                      {({meta, ...props}: FieldProps) =>
                        <NumericFormat
                          {...props}
                          id={`${id}.sellingPrice`}
                          onValueChange={({floatValue}) =>
                            setFieldValue(`${id}.sellingPrice`, floatValue ?? '')}
                          value={values[id]?.sellingPrice || ''}
                          onBlur={props.field.onBlur}
                          decimalScale={currency?.minorUnit}
                          className={styles['fixed-width']}
                          {...sharedNumericInputProps}
                        />}
                    </Field>
                    {(isWholesale || sortedRatesForThisCurrency.length === 1)
                      ? <IconButton
                          icon="delete_forever"
                          onClick={() => openDeleteConfirmDialog({
                            input: id,
                            displayedIds,
                            dialogTitle: `Удалить ${isWholesale ? "оптовый" : "розничный"} курс?`,
                          })}
                          wrapperClassName="text-secondary ms-2"
                          disabled={actionLoading}
                        />
                      : <div className="ms-2" style={{width: 24, height: 24}}/>}
                  </div>
              
                return <div
                  className={classNames(styles['padded-row'], styles['striped-row'], "d-flex flex-column d-lg-table-row")}
                  key={currencyId}>
                  <div className={classNames("d-block d-lg-table-cell", isMobileLayout && "mb-1")}>
                    <div className="d-flex align-items-center">
                      <img
                        alt={currency?.name}
                        className={classNames("me-2_5", styles.flag)}
                        src={currency?.iconUrl ?? ''}
                        width={48}
                      />
                      <div className="d-flex align-items-center d-lg-block">
                        <div>{currency?.name}</div>
                        <div className={classNames(styles['gray'], styles['subtitle'])}>
                          Курс за {currency?.defaultQuantity || 1} {displayedCurrencyId}
                        </div>
                      </div>
                    </div>
                  </div>
                  {isMobileLayout
                    ? <>
                        {sortedRatesForThisCurrency.map((form, index) =>
                          <div className="d-flex align-items-center" key={form.id}>
                            {renderWholesaleFormPart(form, index)}
                            {renderBuyingPriceFormPart(form, index)}
                            {renderSellingPriceFormPart(form, index)}
                          </div>)}
                        <Button
                          variant='link'
                          className="d-flex align-items-center btn-link-flush mt-1"
                          onClick={() => addRate({currencyId, isWholesale: true, conditionOrder: highestWholesaleOrder + 1})}
                          disabled={actionLoading}>
                          <Icon icon={'add_circle_outline'} className="me-2"/>
                          Добавить оптовый курс
                        </Button>
                      </>
                    : <>
                        <div className="d-table-cell">
                          {sortedRatesForThisCurrency.map(renderWholesaleFormPart)}
                          {hasWholesaleRates && <Button
                              variant='link'
                              className="mt-2"
                              disabled={actionLoading}
                              onClick={() => addRate({currencyId, isWholesale: true, conditionOrder: highestWholesaleOrder + 1})}>
                              Добавить оптовый курс
                          </Button>}
                        </div>
                        <div className="d-table-cell">
                          {sortedRatesForThisCurrency.map(renderBuyingPriceFormPart)}
                        </div>
                        <div className="d-table-cell">
                          {sortedRatesForThisCurrency.map(renderSellingPriceFormPart)}
                        </div>
                      </>}
                </div>
              })}
            </div>
          </>
          : <div>Курсов нет</div>}
      
        {actionError && <Alert className="mt-2 d-flex justify-content-between align-items-center" variant="danger">
          {actionError}
            <IconButton icon="cancel" onClick={() => dispatch(clearRatesEditError())}/>
        </Alert>}
        {updateError && <Alert className="mt-2 d-flex justify-content-between align-items-center">
          {updateError}
            <IconButton icon="cancel" onClick={() => dispatch(clearRatesUpdateError())}/>
        </Alert>}
      
        {showIntervalUpdateChange && <Alert className="mt-2" variant="warning">
            Со времени открытия страницы произошли изменения курсов валют, и, возможно, текущие изменения перетрут информацию внесенную ранее.
        </Alert>}
      
        {isMobileLayout && <div className="d-flex justify-content-end">
            <Button
                variant='link'
                className={classNames("mt-2 me-2")}
                onClick={openCurrencyAddDialog}
                disabled={actionLoading}>
              Настроить список валют
            </Button>
        </div>}
      
        <div className="d-flex align-items-center justify-content-between justify-content-sm-end mt-3_5 mx-2 mx-lg-0 mb-3 mb-lg-0">
          {!!usedCurrencyIds.length && <div className="me-3_5">
            {actionLoading
              ? <Loader/>
              : <>
                  <div className="d-flex align-items-center">
                    {showActualityWarning
                      ? <WarningIcon className="me-1"/>
                      : <Icon
                          icon={"check_circle"}
                          className="text-success me-1"
                          // для визуального выравнивания
                          style={{height: 22}}
                        />}
                    <span
                      className={classNames(styles['large'], showActualityWarning && "text-danger")}>{earliestRefreshTime}</span>
                  </div>
                  <div className={classNames("d-none d-sm-block", styles['gray'])}>Последнее обновление</div>
                </>}
          </div>}
          <Button className={styles['shrinkable']} disabled={actionLoading} size="lg" type={"submit"} variant="primary">
            ОБНОВИТЬ КУРСЫ
          </Button>
        </div>
      </Col>
      <Col className="d-none d-lg-block" lg={3}>
        <div className="mb-2">Добавленные валюты</div>
        {usedCurrencies.map(({id, iconUrl, name}) =>
          <div className="d-flex align-items-center mb-1" key={id}>
            <img alt={name} src={iconUrl || ''} width={24}/>
            <div className="ms-2 flex-grow-1">{name}</div>
            <IconButton
              icon={"delete_forever"}
              onClick={() => openDeleteConfirmDialog({
                input: getCurrencyRateIds(id),
                displayedIds,
                dialogTitle: "Удалить все курсы валюты?",
                dialogDesc: "Будут удалены розничный и все оптовые курсы валюты.",
              })}
              wrapperClassName="text-secondary ms-2"
              disabled={actionLoading}
            />
          </div>)}
        <div className="mt-3 mb-2">Дополнительные валюты</div>
        <div className={styles['scrollable-container']}>
          {unusedCurrencies.map(({id, iconUrl, name}) =>
            <div className="d-flex align-items-center mb-1" key={id}>
              <img alt={name} src={iconUrl || ''} width={24}/>
              <div className="ms-2 flex-grow-1">{name}</div>
              <IconButton
                icon={"add_circle_outline"}
                onClick={() => addRate({currencyId: id})}
                wrapperClassName="text-secondary ms-2"
                disabled={actionLoading}
              />
            </div>)}
        </div>
      </Col>
    </Row>
  
    <ConfirmDialog
      show={!!rateIdsAboutToBeDeleted.length}
      onHide={closeDeleteConfirmDialog}
      title={rateDeleteConfirmDialogTitle}
      description={rateDeleteConfirmDialogDesc}
      actionHandler={removeRates}
      actionButtonLabel={"Удалить"}
    />
  
    <ConfirmDialog
      show={isListDeleteConfirm}
      onHide={closeListDeleteConfirmDialog}
      title={`${listTitle} будет удален. Вы подтверждаете удаление?`}
      actionHandler={deleteList}
      actionButtonLabel={"Да"}
    />
  
    {isMobileLayout && <RatesListCurrenciesDialog
        show={isCurrencyAddDialog}
        onHide={closeCurrencyAddDialog}
        usedCurrencyIds={usedCurrencyIds}
        applyCurrencyChanges={applyCurrencyChanges}
    />}
  </Form>
}

const getRatesListFormInitialValues = (rates: ExchangeRateResponse[]) => {
  const iv: RatesListFormsValues = {};
  
  rates.forEach(({
                   id,
                   currencyId,
                   buyingPrice,
                   sellingPrice,
                   conditionFrom,
                   conditionTo,
                 }) => {
    iv[String(id)] = {
      id: String(id),
      currencyId,
      buyingPrice: buyingPrice || '',
      sellingPrice: sellingPrice || '',
      conditionFrom: conditionFrom || '',
      conditionTo: conditionTo || '',
      isWholesale: !!conditionFrom,
      conditionOrder: conditionFrom || 0,
    }
  })
  
  return iv;
}

export default RatesList;
