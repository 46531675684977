import {combineReducers} from "redux";
import config from "../../features/config/slice";
import dictionaryCurrencies from "../../common/slices/dictionaryCurrencies";
import rates from "../../features/rates/redux/slice";
import cities from "../../common/slices/cities";
import branches from "../../common/slices/branches";

const combinedReducers = combineReducers({
  config,
  dictionaryCurrencies,
  rates,
  cities,
  branches,
})

const reducer = (
  state: Parameters<typeof combinedReducers>[0],
  action: Parameters<typeof combinedReducers>[1],
): ReturnType<typeof combinedReducers> => {
  return combinedReducers(state, action);
}

export default reducer;
