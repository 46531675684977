import React, {memo} from 'react';
import classNames from "classnames";
import {IconProps} from "./types";
import styles from './Icon.module.scss';

/**
 * Компонент - иконка с https://material.io/resources/icons/?style=baseline
 */
const Icon: React.FC<IconProps> = ({
  state,
  icon,
  className,
  style,
}) =>
  <i className={classNames(
    `material-icons-outlined`,
    className,
    styles.icon,
    state?.map(cl => styles[cl]),
  )} style={style}>
    {icon}
  </i>

export default memo(Icon);
