import React from 'react';
import styles from "./TextInput.module.scss";
import {TextInputProps} from "./types";
import classNames from "classnames";
import _get from "lodash.get";

/**
 * Компонент текстового поля ввода
 */
const TextInput = React.forwardRef<unknown, TextInputProps>(({
  field,
  wrapperClassName,
  formObject,
  form,
  ...inputProps
}, _) => {
  const name = inputProps.name || (field && field.name);

  /**
   * Проверка наличия ошибки
   */
  let isError = false;

  if (name) {
    if (formObject) {
      isError = _get(formObject.touched, name) && _get(formObject.errors, name);
    } else if (form && typeof form === "object") {
      isError = _get(form.touched, name) && _get(form.errors, name);
    }
  }
  
  return <>
    <div className={classNames(
      styles['input-wrapper'],
      isError && styles['input-wrapper--error'],
      wrapperClassName,
    )}>
      <input
        className="form-control"
        type="text"
        autoComplete="off"
        {...field}
        {...inputProps}
      />
    </div>
  </>
})

export default TextInput;
