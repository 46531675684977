import {
  EntityListState,
  RootState,
} from "../../app/store/store";
import {BranchResponse} from "../../features/config/models";
import {createSlice} from "@reduxjs/toolkit";
import {
  entityListFailureCaseReducer,
  entityListRequestCaseReducer,
  entityListSuccessCaseReducer
} from "../../app/store/helpers";

type BranchesState = EntityListState<BranchResponse>

const initialState: BranchesState = {
  data: [],
  loading: false,
  error: null
};

const branchesSlice = createSlice({
  name: 'branches',
  initialState,
  reducers: {
    getBranchesRequest: entityListRequestCaseReducer,
    getBranchesSuccess: entityListSuccessCaseReducer,
    getBranchesFailure: entityListFailureCaseReducer,
  }
})

/**
 * Селектор отделений вне компонента, чтобы идентичность функции была стабильна и не вызывала ререндеров
 */
export const branchesListSelector = ({branches: {data}}: RootState): BranchesState["data"] => data;
export const branchesLoadingSelector = ({branches: {loading}}: RootState): BranchesState["loading"] => loading;
export const branchesErrorSelector = ({branches: {error}}: RootState): BranchesState["error"] => error;

export const {
  getBranchesRequest,
  getBranchesSuccess,
  getBranchesFailure,
} = branchesSlice.actions;
export default branchesSlice.reducer;
