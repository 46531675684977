import {CaseReducer, PayloadAction} from "@reduxjs/toolkit";
import {EntityListState} from "./store";

export const entityListRequestCaseReducer: CaseReducer = () => ({
  data: [],
  loading: true,
  error: null,
})

export function entityListSuccessCaseReducer<Entity = unknown[]>(
  _: EntityListState<Entity>,
  action: PayloadAction<Entity[]>
): EntityListState<Entity> {
  return {
    data: action.payload,
    loading: false,
    error: null,
  };
}

export function entityListFailureCaseReducer<Entity = unknown[]>(
  _: EntityListState<Entity>,
  action: PayloadAction<string>
): EntityListState<Entity> {
  return {
    data: [],
    loading: false,
    error: action.payload,
  };
}
