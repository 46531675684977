import {ExtendedExchangeRateForm} from "./components/RatesOutput/types";
import {CurrencyResponse, ExchangeRateResponse} from "./models";

/**
 * Получить функцию сравнения валют по их индексу в справочнике валют dictionaryCurrencies
 */
export const getCompareCurrencyRatesEntries = (
  dictionaryCurrencies: CurrencyResponse[],
) =>
  (
    o1: [string, unknown],
    o2: [string, unknown],
  ): number =>
    dictionaryCurrencies.findIndex(({id}) => id === o1[0]) - dictionaryCurrencies.findIndex(({id}) => id === o2[0])

/**
 * Сравнение форм курсов по специальному индексу порядка.
 * Индекс порядка основан на conditionFrom, но для существующих курсов вычисляется только в начале и не изменяется,
 * а для добавленных курсов индекс порядка всегда больше любого индекса порядка существующего курса,
 * так что добавленные курсы всегда оказываются в конце списка, доже если они меньше по "от".
 */
export const compareExchangeRateForms = (
  {conditionOrder: conditionOrder1}: ExtendedExchangeRateForm,
  {conditionOrder: conditionOrder2}: ExtendedExchangeRateForm,
): number => (conditionOrder1 || 0) - (conditionOrder2 || 0);

export type RatesOutputTab = {
  key: string;
  /** название вкладки */
  title: string | JSX.Element;
  /** название вкладки для отображения внутри вкладки */
  innerTitle: string;
  cityId: number | null;
  branchId: number | null;
  rates: ExchangeRateResponse[];
  showActualityWarning: boolean;
}

/**
 * Сравнить вкладки: по порядку отображения должны быть сначала общий курс, затем города и потом отделения
 */
export const compareRateTabs = (
  tab1: RatesOutputTab,
  tab2: RatesOutputTab,
): number => {
  const index1 = (typeof tab1.cityId === "number" ? 1 : 0) + (typeof tab1.branchId === "number" ? 1 : 0);
  const index2 = (typeof tab2.cityId === "number" ? 1 : 0) + (typeof tab2.branchId === "number" ? 1 : 0);
  return index1 - index2;
}

export const RATE_TABS_GENERAL_KEY = 'general';

/**
 * Получить ключ вкладки курса
 */
export const getRateTabKey = (
  cityId: number | string | null,
  branchId: number | string | null,
): string => {
  if (!cityId) {
    return RATE_TABS_GENERAL_KEY;
  }
  
  if (branchId) {
    return `${cityId}-${branchId}`;
  }
  
  return `${cityId}`;
}
